/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { MDXProvider } from '@mdx-js/react';
import * as runtime from 'react/jsx-runtime';
import remarkGfm from 'remark-gfm';
import rehypePrism from 'rehype-prism-plus';
import matter from 'gray-matter';
import { Buffer } from 'buffer';
import { evaluate } from '@mdx-js/mdx';

// Make Buffer available globally for gray-matter
declare global {
  interface Window {
    Buffer: typeof Buffer;
  }
}
if (typeof window !== 'undefined') {
  window.Buffer = Buffer;
}

// Custom components for MDX
const components = {
  h1: (props: any) => <h1 className="text-4xl font-display font-bold mb-6" {...props} />,
  h2: (props: any) => <h2 className="text-3xl font-display font-bold mt-12 mb-6" {...props} />,
  h3: (props: any) => <h3 className="text-2xl font-display font-bold mt-8 mb-4" {...props} />,
  p: (props: any) => <p className="text-gray-300 mb-6 leading-relaxed" {...props} />,
  ul: (props: any) => <ul className="list-disc list-inside text-gray-300 mb-6 space-y-2" {...props} />,
  ol: (props: any) => <ol className="list-decimal list-inside text-gray-300 mb-6 space-y-2" {...props} />,
  li: (props: any) => <li className="text-gray-300" {...props} />,
  a: (props: any) => (
    <a
      className="text-primary hover:text-primary-light transition-colors duration-200"
      target={props.href?.startsWith('http') ? '_blank' : undefined}
      rel={props.href?.startsWith('http') ? 'noopener noreferrer' : undefined}
      {...props}
    />
  ),
  table: (props: any) => (
    <div className="overflow-x-auto mb-6">
      <table className="min-w-full divide-y divide-gray-800" {...props} />
    </div>
  ),
  thead: (props: any) => <thead className="bg-dark-lighter" {...props} />,
  th: (props: any) => (
    <th
      className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
      {...props}
    />
  ),
  td: (props: any) => <td className="px-6 py-4 text-gray-300" {...props} />,
  tr: (props: any) => <tr className="border-b border-gray-800" {...props} />,
  code: (props: any) => (
    <code
      className="bg-dark-lighter rounded px-2 py-1 font-mono text-sm"
      {...props}
    />
  ),
  pre: (props: any) => (
    <pre className="bg-dark-lighter rounded-lg p-4 mb-6 overflow-x-auto" {...props} />
  ),
};

interface BlogPost {
  title: string;
  excerpt: string;
  date: string;
  author: string;
  category: string;
  image: string;
  content: string;
}

const BlogPost = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<BlogPost | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [Content, setContent] = useState<React.ComponentType | null>(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`/content/blog/${slug}.mdx`);
        if (!response.ok) {
          throw new Error(`Post not found: ${response.status} ${response.statusText}`);
        }

        const mdxText = await response.text();

        const { data, content } = matter(mdxText);

        // Evaluate MDX content
        const { default: MDXContent } = await evaluate(content, {
          ...runtime,
          format: 'mdx',
          remarkPlugins: [remarkGfm],
          rehypePlugins: [rehypePrism],
        });

        setPost({
          title: data.title,
          excerpt: data.excerpt,
          date: data.date,
          author: data.author,
          category: data.category,
          image: data.image,
          content: content
        });

        setContent(() => MDXContent);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-dark flex items-center justify-center">
        <div className="text-gray-300">Loading...</div>
      </div>
    );
  }

  if (!post || !Content) {
    return (
      <div className="min-h-screen bg-dark flex items-center justify-center">
        <div className="text-gray-300">Post not found</div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{post.title} | Catena Blog</title>
        <meta name="description" content={post.excerpt} />
      </Helmet>

      <div className="min-h-screen bg-dark">
        {/* Hero Section */}
        <section className="pt-32 pb-20 relative overflow-hidden">
          <div className="absolute inset-0 grid-pattern opacity-20" />
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative">
            <motion.div
              className="max-w-4xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <Link
                to="/company/blog"
                className="inline-flex items-center text-gray-400 hover:text-primary mb-8 transition-colors duration-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                Back to Blog
              </Link>

              <div className="flex items-center space-x-4 mb-6">
                <span className="px-3 py-1 rounded-full bg-primary/20 text-primary text-sm">
                  {post.category}
                </span>
                <span className="text-gray-400 text-sm">{post.date}</span>
              </div>
              <h1 className="text-4xl md:text-6xl font-display font-bold mb-6">
                {post.title}
              </h1>
              <p className="text-xl text-gray-300 mb-8">{post.excerpt}</p>
              <div className="flex items-center text-gray-400 mb-12">
                <span>By {post.author}</span>
              </div>
              <div className="relative h-[400px] rounded-xl overflow-hidden mb-12">
                <img
                  src={post.image}
                  alt={post.title}
                  className="w-full h-full object-cover"
                />
              </div>
            </motion.div>
          </div>
        </section>

        {/* Content Section */}
        <section className="py-12">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <motion.article
              className="prose prose-invert prose-lg max-w-4xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <MDXProvider components={components}>
                <Content />
              </MDXProvider>
            </motion.article>
          </div>
        </section>
      </div>
    </>
  );
};

export default BlogPost;
