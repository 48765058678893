import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import ScrollToTop from './components/ScrollToTop'

import Home from './pages/Home'
import NotFound from './pages/NotFound'
import Gateway from './pages/solutions/gateway'
import ConnectPage from './pages/solutions/connect'
import TelematicsPage from './pages/solutions/telematics'
import Login from './pages/login'
import ForgotPassword from './pages/forgot-password'
import TeamPage from './pages/company/team'
import Blog from './pages/company/blog'
import BlogPost from './pages/blog/BlogPost'

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />

          <Route path="/solutions/gateway" element={<Gateway />} />
          <Route path="/solutions/connect" element={<ConnectPage />} />
          <Route path="/solutions/telematics" element={<TelematicsPage />} />

          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          <Route path="/company/team" element={<TeamPage />} />
          <Route path="/company/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
