import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const footerLinks = [
    {
      title: 'Platform',
      links: [
        { name: 'Overview', href: '/' }
      ]
    },
    {
      title: 'Solutions',
      links: [
        { name: 'Telematics', href: '/solutions/telematics' },
        { name: 'Gateway', href: '/solutions/gateway' },
        { name: 'Connect', href: '/solutions/connect' }
      ]
    },
    {
      title: 'Resources',
      links: [
        { name: 'Team', href: '/company/team' },
        { name: 'Blog', href: '/company/blog' }
      ]
    }
  ];

  return (
    <footer className="bg-dark relative pt-20 pb-10 overflow-hidden">
      <div className="absolute inset-0 grid-pattern opacity-20" />

      <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="mb-16 grid grid-cols-1 lg:grid-cols-4 gap-12 lg:gap-8">
          <div className="lg:col-span-2">
            <Link to="/" className="flex items-center space-x-3 mb-6">
              <div className="relative w-9 h-9">
                <img
                  src="/icon-mono.png"
                  alt="Catena Logo"
                  className="w-9 h-9 object-contain"
                />
              </div>
              <span className="font-display font-bold text-xl tracking-tight text-white">Catena</span>
            </Link>

            <p className="text-gray-400 mb-6 max-w-md">
              Providing APIs and infrastructure for supply chain developers to easily build and maintain applications.
            </p>

            <div className="flex space-x-5">
              <a
                href="https://www.linkedin.com/company/catena-clearing/"
                target="_blank"
                rel="noopener noreferrer"
                className="w-10 h-10 rounded-full bg-dark-lighter flex items-center justify-center text-gray-400 hover:text-primary transition-colors duration-300"
                aria-label="LinkedIn"
              >
                <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                </svg>
              </a>
              <a
                href="https://x.com/CatenaClearing"
                target="_blank"
                rel="noopener noreferrer"
                className="w-10 h-10 rounded-full bg-dark-lighter flex items-center justify-center text-gray-400 hover:text-primary transition-colors duration-300"
                aria-label="X (Twitter)"
              >
                <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                </svg>
              </a>
              <a
                href="mailto:sales@catenaclearing.io"
                className="inline-flex items-center px-4 py-2 bg-dark-lighter rounded-md border border-gray-700 text-gray-300 hover:text-white hover:border-primary transition-colors duration-300"
              >
                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
                sales@catenaclearing.io
              </a>
            </div>

          </div>

          <div className="lg:col-span-2 grid grid-cols-2 md:grid-cols-3 gap-8">
            {footerLinks.map((column) => (
              <div key={column.title}>
                <h3 className="text-white font-display font-semibold text-lg mb-4">
                  {column.title}
                </h3>
                <ul className="space-y-3">
                  {column.links.map((link) => (
                    <li key={link.name}>
                      <Link
                        to={link.href}
                        className="text-gray-400 hover:text-primary transition-colors duration-300"
                      >
                        {link.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <div className="border-t border-gray-800 pt-8 mt-8 flex flex-col md:flex-row justify-between items-center">
          <p className="text-gray-500 text-sm mb-4 md:mb-0">
            &copy; {currentYear} Catena, Inc. All rights reserved.
          </p>

          <div className="flex flex-wrap justify-center gap-6">
            <a
              href="https://cdn.prod.website-files.com/6633b37be0cf9e6d72a205ca/664511af9da1e88312f22671_Website%20-%20Privacy%20Policy.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-500 text-sm hover:text-primary transition-colors duration-300"
            >
              Privacy Policy
            </a>
            <a
              href="https://cdn.prod.website-files.com/6633b37be0cf9e6d72a205ca/664511afc5fc976cdf696a1c_Website%20-%20Terms%20and%20Conditions.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-500 text-sm hover:text-primary transition-colors duration-300"
            >
              Terms of Service
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
