import { motion, useScroll, useTransform } from 'framer-motion';
import { useState, useEffect, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';

interface TeamMember {
  name: string;
  role: string;
  image: string;
  bio: string;
  linkedin: string;
}

const TeamPage = () => {
  const [randomizedTeamMembers, setRandomizedTeamMembers] = useState<TeamMember[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const { scrollY } = useScroll();
  const y = useTransform(scrollY, [0, 300], [0, 100]);

  const coFounders = useMemo(() => [
    {
      name: 'Jeremy Baksht',
      role: 'CEO & Co-Founder',
      image: '/team/jeremy_baksht.jpeg',
      bio: 'Jeremy is a data expert with senior roles at Walmart, Bloomberg, Citigroup, and several startups.',
      linkedin: 'https://www.linkedin.com/in/baksht/'
    },
    {
      name: 'Mike Goynes',
      role: 'CTO & Co-Founder',
      image: '/team/mike_goynes.jpeg',
      bio: 'Mike has led supply chain data and tech teams for decades, including at Interos, Optoro, Simplexity, and InPhonic.',
      linkedin: 'https://www.linkedin.com/in/mike-goynes/'
    },
    {
      name: 'Travis May',
      role: 'Chairman & Co-Founder',
      image: '/team/travis_may.jpeg',
      bio: 'Travis is the founder of Shaper Capital, launching world-changing data companies. He previously founded Datavant and LiveRamp.',
      linkedin: 'https://www.linkedin.com/in/stmay/'
    }
  ], []);

  const otherTeamMembers = useMemo(() => [
    {
      name: 'Neal Hargis',
      role: 'Senior Sales Engineer',
      image: '/team/neal_hargis.jpeg',
      bio: 'Neal is a supply chain and logistics expert with experience from Vizion, Chain.io, and BlueGrace Logistics.',
      linkedin: 'https://www.linkedin.com/in/neal-hargis/'
    },
    {
      name: 'Josh Meek',
      role: 'Principal Software Engineer',
      image: '/team/josh_meek.jpeg',
      bio: 'Josh is a versatile software engineer with contributions to NASA, Kensho, Prefect, and Interos.',
      linkedin: 'https://www.linkedin.com/in/josh-meek/'
    },
    {
      name: 'Pranav Kayastha',
      role: 'Founding Engineer',
      image: '/team/pranav_kayastha.jpeg',
      bio: 'Pranav has a distinguished career in software development with previous experience at Vecra AI and Interos.',
      linkedin: 'https://www.linkedin.com/in/pkayastha01/'
    },
    {
      name: 'André Sionek',
      role: 'Principal Software Engineer',
      image: '/team/andre_sionek.jpeg',
      bio: 'André is an experienced software engineer with expertise in AWS infrastructure, APIs, and data engineering, contributing to Volteras, Util, and Gousto.',
      linkedin: 'https://www.linkedin.com/in/andresionek/'
    }
  ], []);

  const fixedLastMember = useMemo(() => ({
    name: 'Vivek Papnai',
    role: 'Software Development Engineer',
    image: '/team/vivek_papnai.jpeg',
    bio: '',
    linkedin: ''
  }), []);

  useEffect(() => {
    const shuffledMembers = [...otherTeamMembers].sort(() => Math.random() - 0.5);
    setRandomizedTeamMembers([...coFounders, ...shuffledMembers, fixedLastMember]);
  }, [coFounders, otherTeamMembers, fixedLastMember]);

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const staggerItem = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Team | Catena</title>
        <meta name="description" content="Meet the team behind Catena - building the future of supply chain technology." />
        <link rel="icon" href="/icon-mono-small-dark.png" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://catenaclearing.io/company/team" />
        <meta property="og:title" content="Team | Catena" />
        <meta property="og:description" content="Meet the team behind Catena - building the future of supply chain technology." />
        <meta property="og:image" content="https://catenaclearing.io/logo-square.png" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://catenaclearing.io/company/team" />
        <meta property="twitter:title" content="Team | Catena" />
        <meta property="twitter:description" content="Meet the team behind Catena - building the future of supply chain technology." />
        <meta property="twitter:image" content="https://catenaclearing.io/logo-square.png" />
      </Helmet>

      <main className="relative">
        {/* Fixed Background */}
        <div className="fixed inset-0 pointer-events-none">
          <div className="absolute inset-0 bg-gradient-to-b from-primary/10 via-dark to-dark opacity-80" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,rgba(42,125,156,0.1),transparent_70%)]" />
        </div>

        {/* Content */}
        <div className="relative min-h-screen bg-dark" ref={containerRef}>
          {/* Hero Section */}
          <section className="relative h-[90vh] flex items-center justify-center overflow-hidden">
            <motion.div
              className="container relative z-10 px-4 sm:px-6 lg:px-8"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <div className="max-w-5xl mx-auto text-center">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2, duration: 0.6 }}
                >
                  <h1 className="text-5xl md:text-7xl font-display font-bold mb-6 tracking-tight">
                    Meet the <span className="gradient-text bg-clip-text text-transparent bg-gradient-to-r from-primary via-blue-400 to-primary">Team</span>
                  </h1>
                  <p className="text-xl md:text-2xl text-gray-300 mb-8 max-w-3xl mx-auto leading-relaxed">
                    We&apos;re building the data backbone of supply chain and logistics, bridging fragmented systems with seamless connectivity.
                  </p>
                </motion.div>
              </div>
            </motion.div>

            <motion.div
              className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-dark to-transparent"
              style={{ y }}
            />
          </section>

          {/* Team Section */}
          <section className="py-20">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
              <motion.div
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-12"
                variants={staggerContainer}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
              >
                {randomizedTeamMembers.map((member, index) => (
                  <motion.div
                    key={member.name}
                    className={`group relative bg-dark-lighter rounded-2xl overflow-hidden border border-gray-800/50 backdrop-blur-sm ${
                      index < 3 ? 'md:col-span-2 lg:col-span-1' : ''
                    }`}
                    variants={staggerItem}
                    whileHover={{ y: -5, scale: 1.02 }}
                    transition={{ duration: 0.2 }}
                  >
                    <div className="relative w-full pt-[100%]">
                      <img
                        src={member.image}
                        alt={member.name}
                        className="absolute inset-0 w-full h-full object-cover transition-all duration-500 group-hover:scale-110 group-hover:blur-sm"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-dark via-dark/50 to-transparent opacity-60 group-hover:opacity-90 transition-opacity duration-500" />

                      {/* Title Overlay */}
                      <div className="absolute inset-0 flex flex-col justify-center px-6">
                        <p className="text-white/90 font-medium mb-1.5 tracking-wider text-sm uppercase">
                          {member.role}
                        </p>
                        <h3 className="text-2xl font-display font-bold text-white">
                          {member.name}
                        </h3>
                      </div>
                    </div>

                    <div className="absolute inset-0 p-6 flex flex-col justify-end opacity-0 group-hover:opacity-100 transition-opacity duration-500 pointer-events-none">
                      <div>
                        <div className="overflow-hidden">
                          <p className="text-gray-300 mb-4 transform translate-y-8 opacity-0 group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-500">
                            {member.bio}
                          </p>
                        </div>
                        {member.linkedin && (
                          <a
                            href={member.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center text-gray-400 hover:text-primary transition-colors duration-300 w-fit pointer-events-auto"
                          >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                            </svg>
                            <span className="ml-2">Connect</span>
                          </a>
                        )}
                      </div>
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            </div>
          </section>

          {/* Advisors Section */}
          <section className="py-20">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
              <motion.div
                className="text-center mb-16"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                <h2 className="text-4xl md:text-5xl font-display font-bold mb-4">
                  Strategic <span className="gradient-text bg-clip-text text-transparent bg-gradient-to-r from-primary via-blue-400 to-primary">Advisors</span>
                </h2>
              </motion.div>
              <motion.div
                className="grid md:grid-cols-3 gap-8 lg:gap-12 max-w-6xl mx-auto"
                variants={staggerContainer}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
              >
                {[
                  {
                    name: 'David Warrick',
                    image: '/advisors/david_warrick.jpeg',
                    linkedin: 'https://www.linkedin.com/in/david-warrick-2b617a3/'
                  },
                  {
                    name: 'Anand Raghuraman',
                    image: '/advisors/anand_raghuraman.jpeg',
                    linkedin: 'https://www.linkedin.com/in/araghuraman/'
                  },
                  {
                    name: 'Peter Coumounduros',
                    image: '/advisors/peter_coumounduros.jpeg',
                    linkedin: 'https://www.linkedin.com/in/peter-coumounduros-7831a95'
                  }
                ].map((advisor) => (
                  <motion.div
                    key={advisor.name}
                    className="group relative bg-dark-lighter rounded-2xl overflow-hidden border border-gray-800/50"
                    variants={staggerItem}
                    whileHover={{ y: -5, scale: 1.02 }}
                    transition={{ duration: 0.2 }}
                  >
                    <div className="relative w-full pt-[100%]">
                      <img
                        src={advisor.image}
                        alt={advisor.name}
                        className="absolute inset-0 w-full h-full object-cover transition-all duration-500 group-hover:scale-110 group-hover:blur-sm"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-dark via-dark/50 to-transparent opacity-60 group-hover:opacity-90 transition-opacity duration-500" />

                      {/* Title Overlay */}
                      <div className="absolute inset-0 flex flex-col justify-center px-6">
                        <h3 className="text-2xl font-display font-bold text-white">
                          {advisor.name}
                        </h3>
                      </div>
                    </div>

                    <div className="absolute inset-0 p-6 flex flex-col justify-end opacity-0 group-hover:opacity-100 transition-opacity duration-500 pointer-events-none">
                      {advisor.linkedin && (
                        <a
                          href={advisor.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center text-gray-400 hover:text-primary transition-colors duration-300 w-fit pointer-events-auto"
                        >
                          <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                          </svg>
                          <span className="ml-2">Connect</span>
                        </a>
                      )}
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            </div>
          </section>

          {/* Investors Section */}
          <section className="py-20">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
              <motion.div
                className="text-center mb-16"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                <h2 className="text-4xl md:text-5xl font-display font-bold mb-4">
                  Backed by Leading <span className="gradient-text bg-clip-text text-transparent bg-gradient-to-r from-primary via-blue-400 to-primary">Investors</span>
                </h2>
              </motion.div>

              <motion.div
                className="max-w-6xl mx-auto"
                variants={staggerContainer}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
              >
                <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                  {[
                    {
                      name: "Shaper Capital",
                      logo: "/investors/shaper.png"
                    },
                    {
                      name: "Teamworthy Ventures",
                      logo: "/investors/teamworthy.png"
                    },
                    {
                      name: "Springtime Ventures",
                      logo: "/investors/springtime.png"
                    }
                  ].map((investor) => (
                    <motion.div
                      key={investor.name}
                      variants={staggerItem}
                      className="relative group"
                    >
                      <div className="relative overflow-hidden p-8 transition-all duration-300">
                        {/* Glow Effect */}
                        <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                          <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,rgba(42,125,156,0.15),transparent_0%)]" />
                        </div>

                        {/* Logo Container */}
                        <div className="relative flex items-center justify-center h-16">
                          <motion.div
                            className="relative w-48 h-full"
                            whileHover={{ scale: 1.30 }}
                            transition={{
                              type: "spring",
                              stiffness: 300,
                              damping: 20
                            }}
                          >
                            <img
                              src={investor.logo}
                              alt={investor.name}
                              className="w-full h-full object-contain filter brightness-0 invert opacity-80 group-hover:opacity-100 transition-all duration-300"
                            />
                          </motion.div>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default TeamPage;
