/* eslint-disable */

import React from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect, useRef, useState } from 'react';
import { FaDatabase, FaCode, FaClock, FaCloud, FaTools, FaUsers, FaMoneyBillWave, FaShareAlt, FaNetworkWired, FaExchangeAlt, FaFileAlt, FaRobot, FaServer } from 'react-icons/fa';
import { TbTransform, TbArrowsRightLeft, TbApi } from 'react-icons/tb';
import { AiOutlineSync } from 'react-icons/ai';
import { BiData } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ConnectAnimation = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [activeStage, setActiveStage] = useState(0);
  const [hoveredStage, setHoveredStage] = useState<number | null>(null);
  const [isHovering, setIsHovering] = useState(false);

  const stages = [
    { name: 'Source', color: '#22C55E', description: 'CSV files, REST APIs, EDI/SFTP, and custom integrations' },
    { name: 'Ingest', color: '#3B82F6', description: 'Secure data collection with automatic schema detection' },
    { name: 'Transform', color: '#8B5CF6', description: 'Map and normalize data to your business requirements' },
    { name: 'Enrich', color: '#EC4899', description: 'Add business context and validate against rules' },
    { name: 'Deliver', color: '#F59E0B', description: 'Push to TMS, ERP, WMS and other systems of record' }
  ];

  useEffect(() => {
    if (!isHovering) {
      const interval = setInterval(() => {
        setActiveStage((prev) => (prev + 1) % stages.length);
      }, 2500);
      return () => clearInterval(interval);
    }
  }, [isHovering, stages.length]);

  const getStagePosition = (index: number) => {
    const spacing = 100 / (stages.length - 1);
    return `${spacing * index}%`;
  };

  return (
    <div
      ref={containerRef}
      className="relative w-full h-[300px] overflow-hidden bg-dark rounded-lg shadow-lg flex items-center justify-center"
      onMouseLeave={() => {
        setHoveredStage(null);
        setIsHovering(false);
      }}
      onMouseEnter={() => setIsHovering(true)}
    >
      {/* Background patterns */}
      <div className="absolute inset-0 grid-pattern opacity-10" />
      <div className="absolute inset-0 bg-gradient-radial from-dark-lighter/20 to-transparent" />

      {/* Stages Container */}
      <div className="relative w-[80%] max-w-[800px]">
        {/* Connection lines between stages */}
        <svg className="absolute inset-0 w-full h-full" style={{ top: '50%', transform: 'translateY(-50%)' }}>
          {stages.map((_, index) => {
            if (index < stages.length - 1) {
              return (
                <motion.path
                  key={`connection-${index}`}
                  d={`M ${getStagePosition(index)} 50 L ${getStagePosition(index + 1)} 50`}
                  stroke={`url(#gradient-${index})`}
                  strokeWidth="2"
                  fill="none"
                  initial={{ pathLength: 0 }}
                  animate={{
                    pathLength: activeStage >= index ? 1 : 0,
                    opacity: activeStage >= index ? 0.6 : 0.2
                  }}
                  transition={{ duration: 0.8, ease: "easeInOut" }}
                />
              );
            }
            return null;
          })}
          <defs>
            {stages.map((stage, index) => {
              if (index < stages.length - 1) {
                return (
                  <linearGradient key={`gradient-${index}`} id={`gradient-${index}`}>
                    <stop offset="0%" stopColor={stage.color} />
                    <stop offset="100%" stopColor={stages[index + 1].color} />
                  </linearGradient>
                );
              }
              return null;
            })}
          </defs>
        </svg>

        {stages.map((stage, index) => (
          <motion.div
            key={index}
            className="absolute"
            style={{
              left: getStagePosition(index),
              top: '50%',
              transform: 'translate(-50%, -50%)'
            }}
            onMouseEnter={() => {
              setHoveredStage(index);
              setIsHovering(true);
            }}
          >
            <motion.div
              className="flex flex-col items-center"
              animate={{
                scale: activeStage === index ? 1.1 : hoveredStage === index ? 1.05 : 1
              }}
              transition={{
                duration: 0.3,
                ease: "easeOut"
              }}
            >
              {/* Stage node */}
              <motion.div
                className="w-14 h-14 rounded-full flex items-center justify-center relative mb-4"
                style={{
                  backgroundColor: 'rgba(17, 24, 39, 0.8)',
                  border: `2px solid ${activeStage >= index ? stage.color : 'rgb(31, 41, 55)'}`,
                }}
              >
                {/* Glow effect */}
                <motion.div
                  className="absolute inset-0 rounded-full"
                  style={{
                    background: `radial-gradient(circle at center, ${stage.color}40, transparent)`,
                  }}
                  animate={{
                    opacity: activeStage === index ? 0.4 : hoveredStage === index ? 0.3 : 0.1
                  }}
                  transition={{
                    duration: 0.3,
                    ease: "easeOut"
                  }}
                />

                {/* Stage number */}
                <span
                  className="text-lg font-medium relative z-10"
                  style={{ color: activeStage >= index ? stage.color : 'rgb(156, 163, 175)' }}
                >
                  {index + 1}
                </span>
              </motion.div>

              {/* Stage label */}
              <motion.div
                className="text-center"
                animate={{
                  opacity: activeStage >= index ? 1 : 0.5
                }}
                transition={{
                  duration: 0.3
                }}
              >
                <div
                  className="text-sm font-medium"
                  style={{ color: activeStage >= index ? 'white' : 'rgb(156, 163, 175)' }}
                >
                  {stage.name}
                </div>
              </motion.div>

              {/* Connection particles */}
              {(activeStage === index && index < stages.length - 1) && (
                <AnimatePresence>
                  {[...Array(3)].map((_, i) => (
                    <motion.div
                      key={`particle-${i}`}
                      className="absolute w-2 h-2 rounded-full z-20"
                      style={{
                        backgroundColor: stage.color,
                        boxShadow: `0 0 6px ${stage.color}60`,
                      }}
                      initial={{
                        x: 0,
                        opacity: 0,
                        scale: 0
                      }}
                      animate={{
                        x: '200%',
                        opacity: [0, 1, 1, 0],
                        scale: 1
                      }}
                      transition={{
                        duration: 2,
                        delay: i * 0.5,
                        repeat: Infinity,
                        ease: "linear",
                        opacity: {
                          times: [0, 0.1, 0.9, 1]
                        }
                      }}
                    />
                  ))}
                </AnimatePresence>
              )}
            </motion.div>
          </motion.div>
        ))}
      </div>

      {/* Stage info box */}
      <AnimatePresence>
        {hoveredStage !== null && (
          <motion.div
            className="absolute bottom-6 left transform -translate-x-1/2 bg-dark-lighter rounded-lg p-3 border border-gray-800"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            style={{
              borderColor: stages[hoveredStage].color,
              background: `linear-gradient(135deg, rgba(17, 24, 39, 0.95), rgba(17, 24, 39, 0.8))`,
              backdropFilter: 'blur(10px)',
              boxShadow: `0 4px 20px ${stages[hoveredStage].color}20`,
              width: '460px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
            transition={{
              duration: 0.2,
              ease: "easeOut"
            }}
          >
            <div className="text-gray-400 text-center">{stages[hoveredStage].description}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const TimelineAnimation = () => {
  const [scrollY, setScrollY] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const { top, height } = containerRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const containerPosition = top - viewportHeight;
        const totalScrollDistance = height;

        // Calculate scroll progress (0 to 1)
        let progress = Math.min(Math.max((0 - containerPosition) / totalScrollDistance, 0), 1);
        setScrollY(progress);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial call

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const lineProgress = Math.min(scrollY * 2, 1);

  return (
    <div ref={containerRef} className="relative w-full py-10">
      <div className="absolute left-1/2 top-0 bottom-0 w-1 bg-gray-800 transform -translate-x-1/2">
        <motion.div
          className="absolute top-0 w-full bg-primary"
          style={{ height: `${lineProgress * 100}%` }}
        />
      </div>

      <div className="relative z-10 space-y-28">
        <TimelineItem
          title="Requirements Gathering"
          description="3-6 Months"
          subtext="Defining scope, data flows, edge cases, and partner systems"
          icon={FaCode}
          direction="left"
          activeAt={0.1}
          scrollProgress={scrollY}
        />
        <TimelineItem
          title="Initial Development"
          description="6-8 Months"
          subtext="Building infrastructure, development, and testing environments"
          icon={FaTools}
          direction="right"
          activeAt={0.25}
          scrollProgress={scrollY}
        />
        <TimelineItem
          title="Testing & QA"
          description="2-4 Months"
          subtext="Handling edge cases, error conditions, and recovery scenarios"
          icon={FaNetworkWired}
          direction="left"
          activeAt={0.4}
          scrollProgress={scrollY}
        />
        <TimelineItem
          title="Production Deployment"
          description="1-2 Months"
          subtext="Setting up monitoring, scaling, and security infrastructure"
          icon={FaCloud}
          direction="right"
          activeAt={0.55}
          scrollProgress={scrollY}
        />
        <TimelineItem
          title="Maintenance"
          description="FOREVER"
          subtext="Fixing bugs, handling schema changes, updating dependencies"
          icon={FaTools}
          direction="left"
          activeAt={0.7}
          scrollProgress={scrollY}
        />
        <TimelineItem
          title="On-Call Support"
          description="24/7/365"
          subtext="Critical infrastructure needs constant monitoring and support"
          icon={FaUsers}
          direction="right"
          activeAt={0.85}
          scrollProgress={scrollY}
        />
      </div>
    </div>
  );
};

interface TimelineItemProps {
  title: string;
  description: string;
  subtext: string;
  icon: React.ComponentType<any>;
  direction: 'left' | 'right';
  activeAt: number;
  scrollProgress: number;
}

const TimelineItem = ({ title, description, subtext, icon: Icon, direction, activeAt, scrollProgress }: TimelineItemProps) => {
  const isActive = scrollProgress >= activeAt;

  return (
    <div className="relative">
      <div className={`flex items-center ${direction === 'left' ? 'flex-row' : 'flex-row-reverse'}`}>
        <motion.div
          className="w-1/2 px-6"
          initial={{ opacity: 0, x: direction === 'left' ? -50 : 50 }}
          animate={{
            opacity: isActive ? 1 : 0,
            x: isActive ? 0 : (direction === 'left' ? -50 : 50)
          }}
          transition={{ duration: 0.5 }}
        >
          <div className={`${direction === 'left' ? 'text-right' : 'text-left'}`}>
            <h3 className="text-xl font-display font-bold mb-2">{title}</h3>
            <p className="text-primary font-bold">{description}</p>
            <p className="text-gray-400 text-sm mt-2">{subtext}</p>
          </div>
        </motion.div>

        <div className="relative">
          <motion.div
            className={`absolute top-1/2 transform -translate-y-1/2 ${direction === 'left' ? '-right-3' : '-left-3'} w-6 h-0.5 bg-primary`}
            initial={{ scaleX: 0 }}
            animate={{ scaleX: isActive ? 1 : 0 }}
            transition={{ duration: 0.3 }}
          />
          <motion.div
            className="w-10 h-10 rounded-full bg-dark-lighter border-2 border-primary flex items-center justify-center z-10"
            animate={{
              scale: isActive ? [1, 1.2, 1] : 1,
              borderColor: isActive ? '#2A7D9C' : 'rgba(42, 125, 156, 0.3)'
            }}
            transition={{ duration: 0.5 }}
          >
            <Icon className={`text-${isActive ? 'primary' : 'gray-500'}`} />
          </motion.div>
        </div>

        <div className="w-1/2" />
      </div>
    </div>
  );
};

const FadeInSection = ({ children }: { children: React.ReactNode }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 }
      }}
      transition={{ duration: 0.5 }}
      className="pointer-events-auto select-text"
    >
      {children}
    </motion.div>
  );
};

interface Feature {
  icon: React.ComponentType<{ className?: string }>;
  title: string;
  description: string;
  items: string[];
}

const FeatureCard = ({ feature }: { feature: Feature }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <motion.div
      className="relative p-6 rounded-lg cursor-pointer transition-all duration-300 bg-dark-lighter text-gray-300 hover:bg-dark border border-gray-800 hover:border-primary group"
      onClick={() => setIsExpanded(!isExpanded)}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
    >
      <div className="flex items-center mb-4">
        <feature.icon className="text-2xl mr-3 text-primary group-hover:text-white transition-colors duration-200" />
        <h3 className="text-lg font-display font-bold">{feature.title}</h3>
      </div>
      <p className="text-sm mb-4 text-gray-400">{feature.description}</p>
      <AnimatePresence initial={false}>
        {isExpanded && (
          <motion.ul
            className="space-y-3 border-t border-gray-800 pt-4 mt-4"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
          >
            {feature.items.map((item, index) => (
              <motion.li
                key={index}
                className="flex items-center text-sm"
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.05 }}
              >
                <span className="text-primary mr-3">✓</span>
                {item}
              </motion.li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
      <div className="absolute top-4 right-4">
        <motion.div
          animate={{ rotate: isExpanded ? 180 : 0 }}
          transition={{ duration: 0.2 }}
        >
          <svg
            className="w-5 h-5 text-gray-500 group-hover:text-white transition-colors duration-200"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </motion.div>
      </div>
    </motion.div>
  );
};

const ConnectPage = () => {
  const features: Feature[] = [
    {
      icon: TbApi,
      title: "Managed API",
      description: "We set up and manage the API so you don't have to",
      items: ["Automatic schema detection", "Custom API endpoints", "Enterprise-grade security", "Versioning and documentation"]
    },
    {
      icon: FaCloud,
      title: "Cloud Optimization",
      description: "Optimize infrastructure costs without compromise",
      items: ["Auto-scaling infrastructure", "Cost-efficient resource allocation", "Multi-region deployment", "Reduced operational overhead"]
    },
    {
      icon: BiData,
      title: "Data Integration",
      description: "Connect to any data source with minimal setup",
      items: ["Support for all major data formats", "Real-time and batch processing", "Automated data validation", "Error handling and recovery"]
    },
    {
      icon: TbTransform,
      title: "Transformation",
      description: "Transform data to match your business needs",
      items: ["Custom data mapping", "Schema evolution handling", "Advanced transformations", "Business rule application"]
    },
    {
      icon: FaDatabase,
      title: "Destination Support",
      description: "Deliver data to any system of record",
      items: ["ERP, TMS, WMS integrations", "Database connectors", "File system support", "Webhook and event delivery"]
    },
    {
      icon: AiOutlineSync,
      title: "Synchronization",
      description: "Keep your data in sync across all systems",
      items: ["Bi-directional synchronization", "Conflict resolution", "Change data capture", "Custom sync schedules"]
    },
    {
      icon: FaMoneyBillWave,
      title: "Cost Savings",
      description: "Save $200K+ in engineering and infrastructure costs",
      items: ["Reduced engineering headcount", "No infrastructure costs", "Faster time to market", "Simplified maintenance"]
    },
    {
      icon: TbArrowsRightLeft,
      title: "Partner Management",
      description: "We handle partner relationships for you",
      items: ["API version management", "Schema change coordination", "Communication handling", "Documentation updates"]
    },
    {
      icon: FaTools,
      title: "24/7 Support",
      description: "Enterprise-grade support whenever you need it",
      items: ["Round-the-clock monitoring", "Rapid incident response", "Proactive maintenance", "Regular health checks"]
    }
  ];

  return (
    <>
      <Helmet>
        <title>Catena Connect | Universal Data Integration</title>
        <meta name="description" content="Integrate and enrich data flows from multiple sources directly into your systems of record, empowering smarter, faster decision-making." />
        <link rel="icon" href="/icon-mono-small-dark.png" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://catenaclearing.io/solutions/connect" />
        <meta property="og:title" content="Catena Connect | Universal Data Integration" />
        <meta property="og:description" content="Integrate and enrich data flows from multiple sources directly into your systems of record, empowering smarter, faster decision-making." />
        <meta property="og:image" content="https://catenaclearing.io/logo-square.png" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://catenaclearing.io/solutions/connect" />
        <meta property="twitter:title" content="Catena Connect | Universal Data Integration" />
        <meta property="twitter:description" content="Integrate and enrich data flows from multiple sources directly into your systems of record, empowering smarter, faster decision-making." />
        <meta property="twitter:image" content="https://catenaclearing.io/logo-square.png" />
      </Helmet>
      <div className="min-h-screen bg-dark select-text">
        {/* Hero Section */}
        <section className="pt-32 pb-20 relative overflow-hidden pointer-events-auto">
          <div className="absolute inset-0 grid-pattern opacity-20 pointer-events-none" />
          <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-b from-transparent to-dark-lighter pointer-events-none" />
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative">
            <motion.div
              className="max-w-4xl mx-auto text-center pointer-events-auto select-text"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <h1 className="text-4xl md:text-6xl font-display font-bold mb-6">
                Catena <span className="gradient-text bg-clip-text text-transparent bg-gradient-to-r from-primary via-blue-400 to-primary">Connect</span>
              </h1>
              <p className="text-xl text-gray-300 mb-12">
                Integrate and enrich data flows from multiple sources directly into your systems of record, empowering smarter, faster decision-making.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <Link
                  to="/#contact"
                  className="btn-primary px-8 py-3"
                >
                  Request a Demo
                </Link>
              </div>
            </motion.div>
          </div>
        </section>

        {/* Animation Section */}
        <section className="py-20 relative overflow-hidden">
          <div className="absolute top-0 left-0 right-0 h-32 bg-gradient-to-t from-transparent to-dark-lighter pointer-events-none" />
          <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-b from-transparent to-dark-lighter pointer-events-none" />
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
              <div className="w-full lg:w-1/2">
                <ConnectAnimation />
              </div>
              <div className="w-full lg:w-1/2">
                <FadeInSection>
                  <h2 className="text-3xl font-display font-bold mb-6">
                    Connect with Any System
                  </h2>
                  <p className="text-xl text-gray-300 mb-6">
                    Catena is engineered on modern cloud architecture to make sure integrations are <span className="text-white font-semibold">developed fast</span>, run <span className="text-white font-semibold">securely</span> at scale, and recover <span className="text-white font-semibold">without data loss</span>.
                  </p>
                  <ul className="space-y-4">
                    <li className="flex items-center text-gray-300">
                      <span className="text-primary mr-3">✓</span>
                      Ingest data from any source in any format
                    </li>
                    <li className="flex items-center text-gray-300">
                      <span className="text-primary mr-3">✓</span>
                      Transform and validate with version control
                    </li>
                    <li className="flex items-center text-gray-300">
                      <span className="text-primary mr-3">✓</span>
                      Enrich with additional context and insights
                    </li>
                    <li className="flex items-center text-gray-300">
                      <span className="text-primary mr-3">✓</span>
                      Deliver to any destination system
                    </li>
                  </ul>
                </FadeInSection>
              </div>
            </div>
          </div>
        </section>

        {/* Integration Journey Section */}
        <section className="py-20 bg-dark-lighter relative overflow-hidden">
          <div className="absolute inset-0 grid-pattern opacity-10 pointer-events-none" />
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <FadeInSection>
              <h2 className="text-3xl font-display font-bold mb-12 text-center select-text">
                Integration is brutally hard and maintenance is even worse
              </h2>
              <TimelineAnimation />
              <div className="mt-16 max-w-3xl mx-auto text-center">
                <h3 className="text-2xl font-display font-bold mb-6 text-primary">
                  Weeks, not years.
                </h3>
                <p className="text-xl text-gray-300">
                  While traditional integration requires massive investment in time and resources, Catena Connect eliminates these burdens, letting you focus on your core business.
                </p>
              </div>
            </FadeInSection>
          </div>
        </section>

        {/* Features Section */}
        <section className="py-20 relative overflow-hidden">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <FadeInSection>
              <h2 className="text-3xl font-display font-bold mb-4 text-center">
                Connect Services
              </h2>
              <p className="text-xl text-gray-300 mb-12 text-center max-w-3xl mx-auto">
                Our comprehensive suite of services handles every aspect of your integration needs
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {features.map((feature, index) => (
                  <FeatureCard
                    key={index}
                    feature={feature}
                  />
                ))}
              </div>
            </FadeInSection>
          </div>
        </section>

        {/* Cost Savings Section */}
        <section className="py-20 relative overflow-hidden">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="bg-dark-lighter rounded-xl overflow-hidden border border-gray-800">
              <div className="grid md:grid-cols-2 gap-0">
                <div className="p-10">
                  <FadeInSection>
                    <h2 className="text-3xl font-display font-bold mb-6">
                      Significant Cost Savings
                    </h2>
                    <p className="text-xl text-gray-300 mb-6">
                      You save $200K+ in salaries to engineers in initial build and maintenance costs.
                    </p>
                    <ul className="space-y-4">
                      <li className="flex items-start">
                        <span className="text-primary mr-3 mt-1">✓</span>
                        <div>
                          <span className="font-semibold text-white">Eliminate infrastructure costs</span>
                          <p className="text-sm text-gray-400">No need to manage servers, databases, or scaling solutions</p>
                        </div>
                      </li>
                      <li className="flex items-start">
                        <span className="text-primary mr-3 mt-1">✓</span>
                        <div>
                          <span className="font-semibold text-white">Reduce engineering headcount</span>
                          <p className="text-sm text-gray-400">No need for specialized integration engineers</p>
                        </div>
                      </li>
                      <li className="flex items-start">
                        <span className="text-primary mr-3 mt-1">✓</span>
                        <div>
                          <span className="font-semibold text-white">Faster time to market</span>
                          <p className="text-sm text-gray-400">Launch in weeks instead of months or years</p>
                        </div>
                      </li>
                    </ul>
                  </FadeInSection>
                </div>
                <div className="bg-dark p-10 flex items-center justify-center">
                  <FadeInSection>
                    <div className="relative flex items-center">
                      <div className="text-center">
                        <div className="text-5xl text-white font-bold mb-2">12-18</div>
                        <div className="text-primary font-medium text-lg">Months</div>
                        <div className="text-sm text-gray-400 mt-1">Traditional Approach</div>
                      </div>

                      <div className="mx-6 text-center">
                        <div className="inline-block bg-primary/20 text-primary px-3 py-1 rounded-full">
                          VS
                        </div>
                      </div>

                      <div className="text-center">
                        <div className="text-5xl text-white font-bold mb-2">2-4</div>
                        <div className="text-primary font-medium text-lg">Weeks</div>
                        <div className="text-sm text-gray-400 mt-1">With Catena Connect</div>
                      </div>

                    </div>
                  </FadeInSection>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-20">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <motion.div
              className="max-w-3xl mx-auto text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              <h2 className="text-3xl font-display font-bold mb-6">
                Ready to Simplify Your Integrations?
              </h2>
              <p className="text-gray-300 mb-8">
                Get started with Catena Connect today and transform how you integrate with your partners.
              </p>
              <Link
                to="/#contact"
                className="btn-primary px-8 py-3"
              >
                Request a Demo
              </Link>
            </motion.div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ConnectPage;
