/* eslint-disable */

import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');

    try {
      // TODO: Implement password reset logic
      setSubmitted(true);
    } catch (err) {
      setError('Failed to send reset email. Please try again.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Reset Password | Catena</title>
        <meta name="description" content="Reset your Catena account password." />
        <link rel="icon" href="/icon-mono-small-dark.png" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://catenaclearing.io/forgot-password" />
        <meta property="og:title" content="Reset Password | Catena" />
        <meta property="og:description" content="Reset your Catena account password." />
        <meta property="og:image" content="https://catenaclearing.io/logo-square.png" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://catenaclearing.io/forgot-password" />
        <meta property="twitter:title" content="Reset Password | Catena" />
        <meta property="twitter:description" content="Reset your Catena account password." />
        <meta property="twitter:image" content="https://catenaclearing.io/logo-square.png" />
      </Helmet>

      <div className="min-h-screen flex items-center justify-center bg-dark">
        <div className="max-w-md w-full mx-4">
          <div className="text-center mb-8">
            <Link to="/" className="inline-block">
              <img src="/icon-mono.png" alt="Catena Logo" className="h-12 w-12 mx-auto mb-4" />
            </Link>
            <h2 className="text-3xl font-display font-bold text-white">Reset your password</h2>
            <p className="mt-2 text-gray-400">
              Enter your email address and we'll send you a link to reset your password.
            </p>
          </div>

          <div className="bg-dark-lighter rounded-xl p-8 shadow-lg border border-gray-800">
            {submitted ? (
              <div className="text-center">
                <div className="mb-4 flex justify-center">
                  <div className="w-12 h-12 rounded-full bg-primary/20 flex items-center justify-center">
                    <svg className="w-6 h-6 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                </div>
                <h3 className="text-xl font-semibold text-white mb-2">Check your email</h3>
                <p className="text-gray-400 mb-6">
                  If an account exists for {email}, we've sent password reset instructions to your email address.
                </p>
                <Link
                  to="/login"
                  className="text-primary hover:text-primary-light transition-colors duration-200"
                >
                  Return to login
                </Link>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-6">
                {error && (
                  <div className="p-4 bg-red-500/10 border border-red-500/20 rounded-lg text-red-500 text-sm">
                    {error}
                  </div>
                )}

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-2">
                    Email address
                  </label>
                  <input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="w-full p-3 rounded-lg bg-dark border border-gray-700 text-white placeholder-gray-400 focus:border-primary focus:ring-1 focus:ring-primary transition duration-200"
                    placeholder="you@company.com"
                  />
                </div>

                <button
                  type="submit"
                  className="w-full btn-primary py-3"
                >
                  Send reset link
                </button>

                <div className="text-center">
                  <Link
                    to="/login"
                    className="text-sm text-primary hover:text-primary-light transition-colors duration-200"
                  >
                    Back to login
                  </Link>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
