/* eslint-disable */

import { useEffect, useRef, useState } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';

interface Particle {
  x: number;
  y: number;
  speed: number;
  progress: number;
  width: number;
  color: string;
  sourceNodeId: number;
  targetNodeId: number;
}

interface Node {
  id: number;
  x: number;
  y: number;
  radius: number;
  color: string;
  type: 'supplier' | 'factory' | 'warehouse' | 'distribution' | 'retail' | 'customer' | 'platform';
  connections: number[];
  label: string;
  isHovered?: boolean;
}

const NetworkVisualizer = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [particles, setParticles] = useState<Particle[]>([]);
  const animationRef = useRef<number>(0);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [initialized, setInitialized] = useState(false);
  const [hoveredNodeId, setHoveredNodeId] = useState<number | null>(null);
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number } | null>(null);

  // Scroll animation control
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"]
  });

  const flowSpeed = useTransform(scrollYProgress, [0, 0.5, 1], [0.05, 0.2, 0.05]);
  const networkScale = useTransform(scrollYProgress, [0, 0.5, 1], [0.95, 1.02, 0.95]);
  const networkOpacity = useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [0.6, 1, 1, 0.6]);

  // Node types with their respective colors and glow effects
  const nodeTypes = {
    supplier: { color: '#4CAF50', glow: 'rgba(76, 175, 80, 0.3)', label: 'Supplier' },
    factory: { color: '#2196F3', glow: 'rgba(33, 150, 243, 0.3)', label: 'Factory' },
    warehouse: { color: '#9C27B0', glow: 'rgba(156, 39, 176, 0.3)', label: 'Warehouse' },
    distribution: { color: '#FF9800', glow: 'rgba(255, 152, 0, 0.3)', label: 'Distribution' },
    retail: { color: '#F44336', glow: 'rgba(244, 67, 54, 0.3)', label: 'Retail' },
    customer: { color: '#3F51B5', glow: 'rgba(63, 81, 181, 0.3)', label: 'Customer' },
    platform: { color: '#607D8B', glow: 'rgba(96, 125, 139, 0.3)', label: 'Platform' }
  };

  const initializeParticles = (nodes: Node[]) => {
    const newParticles: Particle[] = [];

    // Create particles for each connection with flow direction logic
    nodes.forEach(node => {
      if (node.type === 'platform') {
        return; // Platform node doesn't send particles
      }

      // Create data ping particles to Catena platform (smaller, faster)
      if (node.connections.includes(4)) { // 4 is the Catena platform node ID
        newParticles.push({
          x: node.x,
          y: node.y,
          speed: 0.02, // Increased speed for data pings to Catena
          progress: Math.random() * 0.9,
          width: 2, // Smaller for data
          color: 'rgba(96, 125, 139, 0.6)', // Platform color
          sourceNodeId: node.id,
          targetNodeId: 4
        });
      }

      // Create physical shipment particles (larger, slower)
      node.connections.forEach(targetId => {
        const targetNode = nodes.find(n => n.id === targetId);
        if (targetNode && targetNode.type !== 'platform') {
          newParticles.push({
            x: node.x,
            y: node.y,
            speed: 0.005, // Slower for physical movement
            progress: Math.random() * 0.9,
            width: 4, // Larger for shipments
            color: getConnectionColor(node.type, targetNode.type),
            sourceNodeId: node.id,
            targetNodeId: targetId
          });
        }
      });
    });

    setParticles(newParticles);
  };

  useEffect(() => {
    if (!initialized && containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setDimensions({ width: rect.width, height: rect.height });

      // Create a supply chain network with Catena platform
      const newNodes: Node[] = [
        // Suppliers (Level 1)
        { id: 1, x: rect.width * 0.1, y: rect.height * 0.2, radius: 18, color: nodeTypes.supplier.color, type: 'supplier', connections: [7, 8, 4], label: 'Raw Materials' },
        { id: 2, x: rect.width * 0.15, y: rect.height * 0.35, radius: 16, color: nodeTypes.supplier.color, type: 'supplier', connections: [7, 4], label: 'Components' },
        { id: 3, x: rect.width * 0.1, y: rect.height * 0.5, radius: 15, color: nodeTypes.supplier.color, type: 'supplier', connections: [8, 4], label: 'Electronics' },

        // Catena Platform (Bottom Left)
        { id: 4, x: rect.width * 0.15, y: rect.height * 0.85, radius: 25, color: nodeTypes.platform.color, type: 'platform', connections: [], label: 'Catena' },

        // Factories (Level 2)
        { id: 7, x: rect.width * 0.35, y: rect.height * 0.3, radius: 20, color: nodeTypes.factory.color, type: 'factory', connections: [9, 10, 4], label: 'Assembly' },
        { id: 8, x: rect.width * 0.35, y: rect.height * 0.5, radius: 18, color: nodeTypes.factory.color, type: 'factory', connections: [10, 4], label: 'Production' },

        // Warehouses (Level 3)
        { id: 9, x: rect.width * 0.5, y: rect.height * 0.2, radius: 18, color: nodeTypes.warehouse.color, type: 'warehouse', connections: [11, 12, 4], label: 'Regional WH' },
        { id: 10, x: rect.width * 0.5, y: rect.height * 0.5, radius: 22, color: nodeTypes.warehouse.color, type: 'warehouse', connections: [11, 12, 13, 4], label: 'Central WH' },

        // Distribution (Level 4)
        { id: 11, x: rect.width * 0.65, y: rect.height * 0.3, radius: 17, color: nodeTypes.distribution.color, type: 'distribution', connections: [14, 15, 4], label: 'North Dist' },
        { id: 12, x: rect.width * 0.7, y: rect.height * 0.5, radius: 16, color: nodeTypes.distribution.color, type: 'distribution', connections: [15, 16, 4], label: 'East Dist' },
        { id: 13, x: rect.width * 0.65, y: rect.height * 0.7, radius: 17, color: nodeTypes.distribution.color, type: 'distribution', connections: [16, 17, 4], label: 'South Dist' },

        // Retail (Level 5)
        { id: 14, x: rect.width * 0.8, y: rect.height * 0.2, radius: 15, color: nodeTypes.retail.color, type: 'retail', connections: [18, 4], label: 'Urban Store' },
        { id: 15, x: rect.width * 0.85, y: rect.height * 0.4, radius: 16, color: nodeTypes.retail.color, type: 'retail', connections: [18, 19, 4], label: 'Mall Outlet' },
        { id: 16, x: rect.width * 0.8, y: rect.height * 0.6, radius: 14, color: nodeTypes.retail.color, type: 'retail', connections: [19, 4], label: 'Suburban' },
        { id: 17, x: rect.width * 0.75, y: rect.height * 0.8, radius: 15, color: nodeTypes.retail.color, type: 'retail', connections: [19, 4], label: 'Rural Store' },

        // Customers (Level 6)
        { id: 18, x: rect.width * 0.95, y: rect.height * 0.3, radius: 17, color: nodeTypes.customer.color, type: 'customer', connections: [4], label: 'B2B' },
        { id: 19, x: rect.width * 0.95, y: rect.height * 0.7, radius: 19, color: nodeTypes.customer.color, type: 'customer', connections: [4], label: 'B2C' }
      ];

      setNodes(newNodes);
      initializeParticles(newNodes);
      setInitialized(true);
    }
  }, [
    containerRef,
    initialized,
    initializeParticles,
    nodeTypes.customer.color,
    nodeTypes.distribution.color,
    nodeTypes.factory.color,
    nodeTypes.platform.color,
    nodeTypes.retail.color,
    nodeTypes.supplier.color,
    nodeTypes.warehouse.color
  ]);

  const getConnectionColor = (sourceType: string, targetType: string) => {
    // Enhanced gradient colors with better opacity
    const colors = {
      'supplier-factory': 'rgba(76, 175, 80, 0.8)',
      'factory-warehouse': 'rgba(33, 150, 243, 0.8)',
      'warehouse-distribution': 'rgba(156, 39, 176, 0.8)',
      'distribution-retail': 'rgba(255, 152, 0, 0.8)',
      'retail-customer': 'rgba(244, 67, 54, 0.8)',
      'platform-connection': 'rgba(96, 125, 139, 0.4)' // Color for platform connections
    };

    if (targetType === 'platform' || sourceType === 'platform') {
      return colors['platform-connection'];
    }

    const key = `${sourceType}-${targetType}` as keyof typeof colors;
    return colors[key] || 'rgba(255, 255, 255, 0.4)';
  };

  // Mouse event handlers
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!canvasRef.current || !containerRef.current) return;

    const rect = containerRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setMousePosition({ x, y });

    // Check if mouse is over any node
    const hoveredNode = nodes.find(node => {
      const dx = node.x - x;
      const dy = node.y - y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      return distance <= node.radius + 5; // Add some padding for easier hovering
    });

    setHoveredNodeId(hoveredNode ? hoveredNode.id : null);
  };

  const handleMouseLeave = () => {
    setHoveredNodeId(null);
  };

  // Get all connected node IDs (direct and indirect)
  const getConnectedNodeIds = (nodeId: number, visited = new Set<number>()): Set<number> => {
    if (visited.has(nodeId)) return visited;

    visited.add(nodeId);
    const node = nodes.find(n => n.id === nodeId);

    if (node) {
      // Add direct connections only to reduce complexity
      node.connections.forEach(targetId => {
        visited.add(targetId);
      });

      // Add nodes that directly connect to this node
      nodes.forEach(otherNode => {
        if (otherNode.connections.includes(nodeId)) {
          visited.add(otherNode.id);
        }
      });
    }

    return visited;
  };

  // Animation loop
  useEffect(() => {
    if (!canvasRef.current || !initialized || particles.length === 0 || nodes.length === 0) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Set canvas dimensions with device pixel ratio for better rendering
    const pixelRatio = window.devicePixelRatio || 1;
    canvas.width = dimensions.width * pixelRatio;
    canvas.height = dimensions.height * pixelRatio;
    canvas.style.width = `${dimensions.width}px`;
    canvas.style.height = `${dimensions.height}px`;
    ctx.scale(pixelRatio, pixelRatio);

    let currentSpeed = 0.05; // Default slow speed

    // Update flow speed from scroll progress
    const unsubscribeFlowSpeed = flowSpeed.onChange(value => {
      currentSpeed = value;
    });

    // Draw with consistent animation frame timing for smoother animation
    let lastTime = 0;
    const FRAME_RATE = 60; // Target frame rate
    const FRAME_TIME = 1000 / FRAME_RATE; // ms per frame

    const animate = (timestamp: number) => {
      // Skip frames to maintain consistent animation speed
      const deltaTime = timestamp - lastTime;
      if (deltaTime < FRAME_TIME) {
        animationRef.current = requestAnimationFrame(animate);
        return;
      }
      lastTime = timestamp;

      ctx.clearRect(0, 0, dimensions.width, dimensions.height);

      // Get connected nodes if hovering
      const connectedNodes = hoveredNodeId
        ? getConnectedNodeIds(hoveredNodeId)
        : new Set<number>();

      // Draw connections with gradient effect - using bezier curves for smoother lines
      nodes.forEach(node => {
        node.connections.forEach(targetId => {
          const targetNode = nodes.find(n => n.id === targetId);
          if (!targetNode) return;

          // Skip drawing connections to the Catena platform node
          if (targetNode.type === 'platform' || node.type === 'platform') return;

          const isHighlighted = hoveredNodeId &&
            (connectedNodes.has(node.id) && connectedNodes.has(targetId));

          // Calculate control points for curved lines
          const dx = targetNode.x - node.x;
          const dy = targetNode.y - node.y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          // Control point offset based on distance
          const cpOffset = distance * 0.2;

          // Determine control points relative to the direction
          const cpX1 = node.x + dx * 0.3;
          const cpY1 = node.y + dy * 0.1;
          const cpX2 = node.x + dx * 0.7;
          const cpY2 = node.y + dy * 0.9;

          // Create gradient for connection line
          const gradient = ctx.createLinearGradient(node.x, node.y, targetNode.x, targetNode.y);
          const baseColor = getConnectionColor(node.type, targetNode.type);

          if (isHighlighted) {
            gradient.addColorStop(0, baseColor.replace('0.8', '1'));
            gradient.addColorStop(1, baseColor.replace('0.8', '1'));
            ctx.lineWidth = 2.5;
          } else {
            gradient.addColorStop(0, baseColor.replace('0.8', '0.15'));
            gradient.addColorStop(1, baseColor.replace('0.8', '0.15'));
            ctx.lineWidth = 1;
          }

          // Draw curved connection line
          ctx.beginPath();
          ctx.moveTo(node.x, node.y);
          ctx.bezierCurveTo(cpX1, cpY1, cpX2, cpY2, targetNode.x, targetNode.y);
          ctx.strokeStyle = gradient;
          ctx.stroke();
        });
      });

      // Create a new array for updated particles
      const updatedParticles = [...particles];

      // Update and draw particles with smoother motion
      updatedParticles.forEach((particle, index) => {
        const sourceNode = nodes.find(n => n.id === particle.sourceNodeId);
        const targetNode = nodes.find(n => n.id === particle.targetNodeId);

        if (!sourceNode || !targetNode) return;

        const isHighlighted = hoveredNodeId &&
          (connectedNodes.has(sourceNode.id) && connectedNodes.has(targetNode.id));

        // Calculate direction vector
        const dx = targetNode.x - sourceNode.x;
        const dy = targetNode.y - sourceNode.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        // Control points for bezier curve
        const cpX1 = sourceNode.x + dx * 0.3;
        const cpY1 = sourceNode.y + dy * 0.1;
        const cpX2 = sourceNode.x + dx * 0.7;
        const cpY2 = sourceNode.y + dy * 0.9;

        // Update progress with small increment to avoid glitches
        particle.progress += particle.speed * currentSpeed * (isHighlighted ? 1.1 : 1);
        if (particle.progress > 1) {
          particle.progress = 0;
        }

        // Calculate position along the bezier curve for smoother movement
        const t = particle.progress;
        const t1 = 1 - t;

        // Cubic bezier curve formula
        particle.x = t1*t1*t1*sourceNode.x + 3*t1*t1*t*cpX1 + 3*t1*t*t*cpX2 + t*t*t*targetNode.x;
        particle.y = t1*t1*t1*sourceNode.y + 3*t1*t1*t*cpY1 + 3*t1*t*t*cpY2 + t*t*t*targetNode.y;

        // Draw particle with subtle glow
        const size = isHighlighted ? particle.width * 1.3 : particle.width;
        const glowSize = size * 2;

        // Draw subtle glow
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, glowSize, 0, Math.PI * 2);
        ctx.fillStyle = particle.color.replace('0.8', '0.1');
        ctx.fill();

        // Draw particle body
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, size, 0, Math.PI * 2);
        ctx.fillStyle = isHighlighted
          ? particle.color.replace('0.8', '0.9')
          : particle.color.replace('0.8', '0.6');
        ctx.fill();

        updatedParticles[index] = particle;
      });

      // Draw nodes on top of connections and particles
      nodes.forEach(node => {
        const isHighlighted = hoveredNodeId && connectedNodes.has(node.id);
        const isHovered = hoveredNodeId === node.id;
        const nodeType = nodeTypes[node.type as keyof typeof nodeTypes] || {
          color: '#607D8B',
          glow: 'rgba(96, 125, 139, 0.3)',
          label: 'Node'
        };

        // Draw outer glow with softer effect
        if (isHighlighted || isHovered) {
          ctx.beginPath();
          const glowRadius = node.radius + (isHovered ? 12 : 8);
          const gradient = ctx.createRadialGradient(
            node.x, node.y, node.radius,
            node.x, node.y, glowRadius
          );
          gradient.addColorStop(0, nodeType.glow.replace('0.3', '0.5'));
          gradient.addColorStop(1, nodeType.glow.replace('0.3', '0'));

          ctx.arc(node.x, node.y, glowRadius, 0, Math.PI * 2);
          ctx.fillStyle = gradient;
          ctx.fill();
        }

        // Draw inner glow with gradient
        ctx.beginPath();
        const innerGlowRadius = node.radius + 3;
        const innerGradient = ctx.createRadialGradient(
          node.x, node.y, node.radius * 0.8,
          node.x, node.y, innerGlowRadius
        );
        innerGradient.addColorStop(0, isHighlighted ? nodeType.glow.replace('0.3', '0.7') : nodeType.glow.replace('0.3', '0.4'));
        innerGradient.addColorStop(1, nodeType.glow.replace('0.3', '0.1'));

        ctx.arc(node.x, node.y, innerGlowRadius, 0, Math.PI * 2);
        ctx.fillStyle = innerGradient;
        ctx.fill();

        // Draw node with gradient for 3D effect
        const nodeGradient = ctx.createRadialGradient(
          node.x - node.radius * 0.3, node.y - node.radius * 0.3, node.radius * 0.1,
          node.x, node.y, node.radius
        );
        nodeGradient.addColorStop(0, isHighlighted ? nodeType.color : `${nodeType.color}ee`);
        nodeGradient.addColorStop(1, isHighlighted ? nodeType.color : `${nodeType.color}aa`);

        ctx.beginPath();
        ctx.arc(node.x, node.y, node.radius, 0, Math.PI * 2);
        ctx.fillStyle = nodeGradient;
        ctx.fill();

        // Draw node border with subtle shadow
        ctx.beginPath();
        ctx.arc(node.x, node.y, node.radius, 0, Math.PI * 2);
        ctx.strokeStyle = isHighlighted ? nodeType.color : `${nodeType.color}aa`;
        ctx.lineWidth = isHighlighted ? 2 : 1.5;
        ctx.stroke();

        // Draw node label with shadow for better readability
        ctx.font = isHighlighted ? 'bold 12px Arial' : '11px Arial';
        ctx.textAlign = 'center';

        // Text shadow
        ctx.fillStyle = 'rgba(0,0,0,0.6)';
        ctx.fillText(node.label, node.x + 1, node.y + node.radius + 16);

        // Actual text
        ctx.fillStyle = '#fff';
        ctx.fillText(node.label, node.x, node.y + node.radius + 15);

        // Draw node type with subtle styling only when hovered
        if (isHovered) {
          // Type label background for better readability
          const typeText = nodeTypes[node.type].label;
          const typeTextWidth = ctx.measureText(typeText).width;

          ctx.fillStyle = 'rgba(10,10,20,0.6)';
          ctx.fillRect(node.x - typeTextWidth/2 - 4, node.y + node.radius + 20, typeTextWidth + 8, 16);

          ctx.font = '10px Arial';
          ctx.fillStyle = '#ddd';
          ctx.fillText(typeText, node.x, node.y + node.radius + 32);
        }
      });

      // Draw connection info when hovering over a node with smoother appearance
      if (hoveredNodeId) {
        const hoveredNode = nodes.find(n => n.id === hoveredNodeId);
        if (hoveredNode) {
          // Count incoming and outgoing connections
          const incoming = nodes.filter(n => n.connections.includes(hoveredNodeId)).length;
          const outgoing = hoveredNode.connections.length;

          // Display info box with smoother corners and gradient
          const infoBoxX = mousePosition?.x || 0 + 20;
          const infoBoxY = mousePosition?.y || 0 - 60;
          const infoBoxWidth = 130;
          const infoBoxHeight = hoveredNode.type === 'platform' ? 55 : 75; // Smaller height for platform
          const cornerRadius = 8;

          // Box shadow
          ctx.shadowColor = 'rgba(0, 0, 0, 0.4)';
          ctx.shadowBlur = 10;
          ctx.shadowOffsetX = 3;
          ctx.shadowOffsetY = 3;

          // Background with gradient
          const boxGradient = ctx.createLinearGradient(
            infoBoxX, infoBoxY,
            infoBoxX, infoBoxY + infoBoxHeight
          );
          boxGradient.addColorStop(0, 'rgba(25, 25, 35, 0.85)');
          boxGradient.addColorStop(1, 'rgba(15, 15, 25, 0.85)');

          ctx.fillStyle = boxGradient;
          ctx.beginPath();
          ctx.roundRect(infoBoxX, infoBoxY, infoBoxWidth, infoBoxHeight, cornerRadius);
          ctx.fill();

          // Border with color matching the node
          ctx.shadowColor = 'transparent';
          ctx.shadowBlur = 0;
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = 0;

          ctx.strokeStyle = nodeTypes[hoveredNode.type].color;
          ctx.lineWidth = 2;
          ctx.stroke();

          // Content with better typography
          ctx.fillStyle = '#fff';
          ctx.font = 'bold 12px Arial';
          ctx.textAlign = 'left';
          ctx.fillText(hoveredNode.label, infoBoxX + 12, infoBoxY + 22);

          // Divider line
          ctx.beginPath();
          ctx.moveTo(infoBoxX + 12, infoBoxY + 32);
          ctx.lineTo(infoBoxX + infoBoxWidth - 12, infoBoxY + 32);
          ctx.strokeStyle = 'rgba(255,255,255,0.2)';
          ctx.lineWidth = 1;
          ctx.stroke();

          // Info text
          ctx.fillStyle = '#bbb';
          ctx.font = '11px Arial';
          ctx.fillText(`Type: ${nodeTypes[hoveredNode.type].label}`, infoBoxX + 12, infoBoxY + 48);

          // Only show connection counts for non-platform nodes
          if (hoveredNode.type !== 'platform') {
            ctx.fillText(`In: ${incoming} · Out: ${outgoing}`, infoBoxX + 12, infoBoxY + 65);
          }
        }
      }

      // Update particles state less frequently to reduce rendering overhead
      if (animationRef.current % 10 === 0) {
        setParticles(updatedParticles);
      }

      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);

    // Cleanup
    return () => {
      cancelAnimationFrame(animationRef.current);
      unsubscribeFlowSpeed();
    };
  }, [getConnectedNodeIds, nodeTypes]);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        setDimensions({ width: rect.width, height: rect.height });

        // Recalculate node positions
        setNodes(prev => {
          return prev.map(node => {
            // Keep the relative positions
            const relativeX = node.x / dimensions.width;
            const relativeY = node.y / dimensions.height;

            return {
              ...node,
              x: relativeX * rect.width,
              y: relativeY * rect.height
            };
          });
        });
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [dimensions]);

  return (
    <div className="container mx-auto px-4 py-12">
      <div className="text-center mb-12">
        <div className="inline-block px-4 py-1 bg-dark-lighter rounded-full border border-gray-700 text-primary font-medium mb-4">
          Universal Data Platform
        </div>
        <h2 className="text-3xl md:text-4xl font-display font-bold mb-4">
          Unifying Supply Chain Data From Every Source
        </h2>
        <p className="text-lg text-gray-300 max-w-3xl mx-auto">
          Seamlessly integrate and normalize data from your entire supply chain ecosystem. From telematics and TMS to ERP and beyond, Catena transforms fragmented data into actionable intelligence.
        </p>
      </div>

      <motion.div
        className="relative h-[500px] overflow-hidden bg-dark rounded-lg shadow-xl border border-gray-800"
        ref={containerRef}
        style={{
          scale: networkScale,
          opacity: networkOpacity
        }}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <canvas ref={canvasRef} className="absolute inset-0" />
      </motion.div>
    </div>
  );
};

export default NetworkVisualizer;
