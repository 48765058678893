/* eslint-disable */

import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaDatabase, FaExchangeAlt, FaFileAlt, FaRobot, FaServer, FaCloud, FaEnvelope, FaFilePdf, FaFileExcel, FaCode } from 'react-icons/fa';
import { IconType } from 'react-icons';
import { Helmet } from 'react-helmet';


interface IconProps {
  type: 'pdf' | 'excel' | 'email' | 'api';
  isActive: boolean;
  delay?: number;
}

interface StructuredIconProps {
  type: 'database' | 'integration' | 'analytics' | 'system';
  isActive: boolean;
  delay?: number;
}

interface ProcessingBoxProps {
  isProcessing: boolean;
}

interface DataFlowArrowProps {
  direction: 'left' | 'right';
  isActive: boolean;
  delay?: number;
}

const DocumentIcon = ({ type, isActive, delay = 0 }: IconProps) => {
  const icons: Record<IconProps['type'], IconType> = {
    pdf: FaFilePdf,
    excel: FaFileExcel,
    email: FaEnvelope,
    api: FaCode
  };

  const colors: Record<IconProps['type'], string> = {
    pdf: "#E74C3C",
    excel: "#27AE60",
    email: "#3498DB",
    api: "#9B59B6"
  };

  const Icon = icons[type];

  return (
    <motion.div
      className="flex flex-col items-center justify-center"
      initial={{ opacity: 0, x: -30 }}
      animate={{
        opacity: isActive ? 1 : 0.5,
        x: 0,
        scale: isActive ? 1.05 : 1
      }}
      transition={{
        duration: 0.4,
        delay,
        scale: {
          duration: 0.2
        }
      }}
    >
      <div
        className={`w-14 h-14 rounded-md flex items-center justify-center mb-2 relative overflow-hidden
          ${isActive ? 'border border-gray-700 shadow-glow' : ''}`}
      >
        {isActive && (
          <motion.div
            className="absolute inset-0"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.1 }}
            style={{
              background: `linear-gradient(135deg, transparent, ${colors[type]})`,
            }}
          />
        )}
        <Icon className="text-xl" style={{ color: colors[type] }} />
      </div>
      <div
        className={`text-xs font-medium px-2 py-0.5 rounded-sm transition-all duration-300
          ${isActive ? 'text-white' : 'text-gray-400'}`}
      >
        {type.toUpperCase()}
      </div>
    </motion.div>
  );
};

const StructuredDataIcon = ({ type, isActive, delay = 0 }: StructuredIconProps) => {
  const icons: Record<StructuredIconProps['type'], IconType> = {
    database: FaDatabase,
    integration: FaExchangeAlt,
    analytics: FaRobot,
    system: FaServer
  };

  const colors: Record<StructuredIconProps['type'], string> = {
    database: "#3498DB",
    integration: "#9B59B6",
    analytics: "#F39C12",
    system: "#16A085"
  };

  const Icon = icons[type];

  return (
    <motion.div
      className="flex flex-col items-center justify-center"
      initial={{ opacity: 0, x: 30 }}
      animate={{
        opacity: isActive ? 1 : 0.5,
        x: 0,
        scale: isActive ? 1.05 : 1
      }}
      transition={{
        duration: 0.4,
        delay,
        scale: {
          duration: 0.2
        }
      }}
    >
      <div
        className={`w-14 h-14 rounded-md flex items-center justify-center mb-2 relative overflow-hidden
          ${isActive ? 'border border-gray-700 shadow-glow' : ''}`}
      >
        {isActive && (
          <motion.div
            className="absolute inset-0"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.1 }}
            style={{
              background: `linear-gradient(135deg, transparent, ${colors[type]})`,
            }}
          />
        )}
        <Icon className="text-xl" style={{ color: colors[type] }} />
      </div>
      <div
        className={`text-xs font-medium px-2 py-0.5 rounded-sm transition-all duration-300
          ${isActive ? 'text-white' : 'text-gray-400'}`}
      >
        {type.toUpperCase()}
      </div>
    </motion.div>
  );
};

const ProcessingBox = ({ isProcessing }: ProcessingBoxProps) => {
  return (
    <motion.div
      className="relative"
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5, delay: 0.3 }}
    >
      <div className="w-40 h-40 rounded-lg flex items-center justify-center bg-dark-lighter/30 backdrop-blur-sm border border-gray-800 shadow-glow">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 25, repeat: Infinity, ease: "linear" }}
          className="absolute inset-4 rounded-md border border-dashed border-primary/20 opacity-50"
        />

        <motion.div
          className="w-24 h-24 rounded-md flex items-center justify-center relative overflow-hidden bg-dark-lighter border border-gray-700"
          animate={{
            boxShadow: isProcessing
              ? ['0 0 10px rgba(42,125,156,0.2)', '0 0 15px rgba(42,125,156,0.4)', '0 0 10px rgba(42,125,156,0.2)']
              : '0 0 10px rgba(42,125,156,0.2)'
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        >
          {isProcessing && (
            <motion.div
              className="absolute inset-0 bg-gradient-to-br from-primary/10 to-secondary/10 opacity-30"
              animate={{
                background: [
                  'linear-gradient(135deg, rgba(42,125,156,0.1), rgba(66,153,225,0.1))',
                  'linear-gradient(225deg, rgba(42,125,156,0.1), rgba(66,153,225,0.1))',
                  'linear-gradient(315deg, rgba(42,125,156,0.1), rgba(66,153,225,0.1))',
                  'linear-gradient(45deg, rgba(42,125,156,0.1), rgba(66,153,225,0.1))',
                  'linear-gradient(135deg, rgba(42,125,156,0.1), rgba(66,153,225,0.1))'
                ]
              }}
              transition={{
                duration: 8,
                repeat: Infinity,
                ease: "linear"
              }}
            />
          )}

          <motion.img
            src="/catena-gateway.png"
            alt="Catena Logo"
            className="w-12 h-12 brightness-0 invert"
            animate={{
              scale: isProcessing ? [1, 1.05, 1] : 1,
              rotate: isProcessing ? [0, 3, 0, -3, 0] : 0
            }}
            transition={{
              duration: 2,
              repeat: isProcessing ? Infinity : 0,
              ease: "easeInOut",
            }}
          />
        </motion.div>
      </div>
    </motion.div>
  );
};

const DataFlowArrow = ({ direction, isActive, delay = 0 }: DataFlowArrowProps) => {
  return (
    <motion.div
      className="flex items-center justify-center w-16"
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.9 }}
      transition={{ duration: 0.5, delay }}
    >
      <motion.div
        className={`w-full h-0.5 bg-gradient-to-r from-primary to-secondary`}
        style={{
          transform: direction === 'right' ? 'none' : 'rotate(180deg)'
        }}
      />
    </motion.div>
  );
};

const GatewayPage = () => {
  const [isProcessing] = useState(true);
  const [activeDocType, setActiveDocType] = useState(0);
  const [activeOutputType, setActiveOutputType] = useState(0);

  const documentTypes = ['pdf', 'excel', 'email', 'api'] as const;
  const outputTypes = ['database', 'integration', 'analytics', 'system'] as const;

  useEffect(() => {
    // Randomly highlight document types
    const docInterval = setInterval(() => {
      setActiveDocType(Math.floor(Math.random() * documentTypes.length));
    }, 2000);

    // Randomly highlight output types with a slight delay
    const outputInterval = setInterval(() => {
      setActiveOutputType(Math.floor(Math.random() * outputTypes.length));
    }, 2000);

    return () => {
      clearInterval(docInterval);
      clearInterval(outputInterval);
    };
  }, [documentTypes.length, outputTypes.length]);

  return (
    <>
      <Helmet>
        <title>Catena Gateway | AI-Driven Data Integration</title>
        <meta name="description" content="AI-driven data integration platform that transforms diverse data sources into unified, structured formats." />
        <link rel="icon" href="/icon-mono-small-dark.png" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://catenaclearing.io/solutions/gateway" />
        <meta property="og:title" content="Catena Gateway | AI-Driven Data Integration" />
        <meta property="og:description" content="AI-driven data integration platform that transforms diverse data sources into unified, structured formats." />
        <meta property="og:image" content="https://catenaclearing.io/logo-square.png" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://catenaclearing.io/solutions/gateway" />
        <meta property="twitter:title" content="Catena Gateway | AI-Driven Data Integration" />
        <meta property="twitter:description" content="AI-driven data integration platform that transforms diverse data sources into unified, structured formats." />
        <meta property="twitter:image" content="https://catenaclearing.io/logo-square.png" />
      </Helmet>

      <div className="min-h-screen bg-dark select-text">
        {/* Hero Section */}
        <section className="pt-32 pb-20 relative overflow-hidden pointer-events-auto">
          <div className="absolute inset-0 grid-pattern opacity-20 pointer-events-none" />
          <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-b from-transparent to-dark-lighter pointer-events-none" />
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative">
            <motion.div
              className="max-w-4xl mx-auto text-center pointer-events-auto select-text"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <h1 className="text-4xl md:text-6xl font-display font-bold mb-6">
                Catena <span className="gradient-text bg-clip-text text-transparent bg-gradient-to-r from-primary via-blue-400 to-primary">Gateway</span>
              </h1>
              <p className="text-xl text-gray-300 mb-12">
                AI-driven data integration platform that transforms diverse data sources into unified, structured formats.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center mb-12">
                <Link to="/#contact" className="btn-primary pointer-events-auto">
                  Request a Demo
                </Link>
              </div>
            </motion.div>
          </div>
        </section>

        {/* Animation Section */}
        <section className="py-20 relative overflow-hidden">
          <div className="absolute top-0 left-0 right-0 h-32 bg-gradient-to-t from-transparent to-dark-lighter pointer-events-none" />
          <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-b from-transparent to-dark-lighter pointer-events-none" />
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
              <div className="w-full lg:w-1/2">
                <div className="rounded-lg p-6 h-[400px] relative overflow-hidden bg-dark-lighter/20 backdrop-blur-sm border border-gray-800 shadow-lg">
                  <div className="absolute inset-0 grid-pattern opacity-10" />
                  <div className="h-full flex items-center justify-center">
                    <div className="flex items-center justify-between w-full max-w-2xl">
                      {/* Input Documents */}
                      <div className="flex flex-col space-y-3">
                        {documentTypes.map((type, index) => (
                          <DocumentIcon
                            key={type}
                            type={type}
                            isActive={activeDocType === index}
                            delay={index * 0.1}
                          />
                        ))}
                      </div>

                      {/* Input Arrow */}
                      <DataFlowArrow direction="right" isActive={true} delay={0.5} />

                      {/* Processing Box */}
                      <ProcessingBox isProcessing={isProcessing} />

                      {/* Output Arrow */}
                      <DataFlowArrow direction="right" isActive={true} delay={0.5} />

                      {/* Output Structured Data */}
                      <div className="flex flex-col space-y-3">
                        {outputTypes.map((type, index) => (
                          <StructuredDataIcon
                            key={type}
                            type={type}
                            isActive={activeOutputType === index}
                            delay={index * 0.1 + 0.5}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.7, delay: 0.2 }}
                >
                  <h2 className="text-3xl font-display font-bold mb-6">
                    Transform Your Data Integration
                  </h2>
                  <p className="text-xl text-gray-300 mb-6">
                    Catena Gateway is an AI-driven data integration platform that extracts structured, semi-structured, and unstructured data from diverse sources and transforms it into a unified, structured format for seamless system integration.
                  </p>
                  <ul className="space-y-4">
                    <li className="flex items-center text-gray-300">
                      <span className="text-primary mr-3">✓</span>
                      Extract data from emails, PDFs, spreadsheets, and APIs
                    </li>
                    <li className="flex items-center text-gray-300">
                      <span className="text-primary mr-3">✓</span>
                      AI-powered processing with OCR and NLP
                    </li>
                    <li className="flex items-center text-gray-300">
                      <span className="text-primary mr-3">✓</span>
                      Seamless integration with existing systems
                    </li>
                    <li className="flex items-center text-gray-300">
                      <span className="text-primary mr-3">✓</span>
                      Real-time data transformation and validation
                    </li>
                  </ul>
                </motion.div>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="py-20 bg-dark-lighter relative overflow-hidden">
          <div className="absolute inset-0 grid-pattern opacity-10 pointer-events-none" />
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="text-center mb-12"
            >
              <h2 className="text-3xl font-display font-bold mb-6">
                Powerful Features
              </h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Built for enterprise-scale data processing with advanced features for complex supply chain operations.
              </p>
            </motion.div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  icon: FaFileAlt,
                  title: "Multi-Source Data Extraction",
                  description: "Extract data from emails, PDFs, spreadsheets, and APIs with intelligent recognition of formats and structures."
                },
                {
                  icon: FaRobot,
                  title: "AI-Powered Processing",
                  description: "Leverage AI models for OCR, NLP, and entity resolution to extract, classify, and standardize data with high accuracy."
                },
                {
                  icon: FaExchangeAlt,
                  title: "Seamless Integration",
                  description: "Connect with ERP, TMS, and CRM systems through standardized schemas and APIs for frictionless data flow."
                },
                {
                  icon: FaDatabase,
                  title: "Intelligent Knowledge Base",
                  description: "AI-managed knowledge base that continuously learns and improves data mapping and transformation accuracy."
                },
                {
                  icon: FaServer,
                  title: "Accelerated Processing",
                  description: "Adaptable system for real-time transformation of large-scale data ingestion and processing."
                },
                {
                  icon: FaCloud,
                  title: "Cloud-Native Architecture",
                  description: "Scalable, resilient cloud infrastructure that adapts to your data volume needs with high availability."
                }
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  className="bg-dark rounded-lg p-6 border border-gray-800 hover:border-primary/30 transition-all duration-300"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <feature.icon className="text-2xl text-primary mb-4" />
                  <h3 className="text-xl font-display font-bold mb-3">{feature.title}</h3>
                  <p className="text-gray-300">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-20">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <motion.div
              className="max-w-3xl mx-auto text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              <h2 className="text-3xl font-display font-bold mb-6">
                Ready to Transform Your Data Integration?
              </h2>
              <p className="text-gray-300 mb-8">
                Contact us today to learn how Catena Gateway can streamline your data workflows and provide real-time visibility into your supply chain.
              </p>
              <Link
                to="/#contact"
                className="btn-primary"
              >
                Request a Demo
              </Link>
            </motion.div>
          </div>
        </section>
      </div>
    </>
  );
};

export default GatewayPage;
