/* eslint-disable */

import { useEffect, useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { Link } from 'react-router-dom';

// Define Node type explicitly outside useEffect
interface NodeInterface {
  x: number;
  y: number;
  size: number;
  color: string;
  connections: NodeInterface[];
  velocity: { x: number; y: number };
  isHub: boolean;
  update: () => void;
  draw: () => void;
}

const Hero = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // Scroll animations
  const { scrollY } = useScroll();
  const y = useTransform(scrollY, [0, 300], [0, 100]);
  const opacity = useTransform(scrollY, [0, 300], [1, 0]);

  const scrollToContact = () => {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToSolutions = () => {
    const solutionsSection = document.getElementById('solutions');
    if (solutionsSection) {
      solutionsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    class Node implements NodeInterface {
      x: number;
      y: number;
      size: number;
      color: string;
      connections: NodeInterface[];
      velocity: { x: number; y: number };
      isHub: boolean;

      constructor(x: number, y: number, isHub = false) {
        this.x = x;
        this.y = y;
        this.connections = [];
        this.isHub = isHub;
        this.size = isHub ? 3 : Math.random() * 1.5 + 1;
        this.color = isHub ? '#2A7D9C' : `rgba(${Math.floor(Math.random() * 100 + 155)}, ${Math.floor(
          Math.random() * 100 + 155
        )}, ${Math.floor(Math.random() * 100 + 155)}, ${Math.random() * 0.3 + 0.2})`;
        this.velocity = {
          x: (Math.random() - 0.5) * 0.3,
          y: (Math.random() - 0.5) * 0.3
        };
      }

      update() {
        // Move slightly
        this.x += this.velocity.x;
        this.y += this.velocity.y;

        // Boundary checks
        if (this.x > canvas.width || this.x < 0) {
          this.velocity.x = -this.velocity.x;
        }

        if (this.y > canvas.height || this.y < 0) {
          this.velocity.y = -this.velocity.y;
        }
      }

      draw() {
        if (!ctx) return;
        ctx.fillStyle = this.color;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fill();
      }
    }

    // Create nodes
    const nodes: Node[] = [];
    const hubCount = 5;
    const nodeCount = Math.min(70, Math.floor(window.innerWidth / 25));

    // Create hub nodes first
    for (let i = 0; i < hubCount; i++) {
      const x = Math.random() * canvas.width;
      const y = Math.random() * canvas.height;
      nodes.push(new Node(x, y, true));
    }

    // Create regular nodes
    for (let i = 0; i < nodeCount; i++) {
      const x = Math.random() * canvas.width;
      const y = Math.random() * canvas.height;
      nodes.push(new Node(x, y));
    }

    // Connect nodes to nearest hubs and some random connections
    nodes.forEach(node => {
      if (!node.isHub) {
        // Find closest hub
        let closestHub: Node | null = null;
        let minDistance = Infinity;

        nodes.forEach(otherNode => {
          if (otherNode.isHub) {
            const dx = node.x - otherNode.x;
            const dy = node.y - otherNode.y;
            const distance = Math.sqrt(dx * dx + dy * dy);

            if (distance < minDistance) {
              minDistance = distance;
              closestHub = otherNode;
            }
          }
        });

        if (closestHub) {
          node.connections.push(closestHub);
          (closestHub as NodeInterface).connections.push(node);
        }

        // Add some random connections
        if (Math.random() > 0.7) {
          const randomNode = nodes[Math.floor(Math.random() * nodes.length)];
          if (randomNode !== node) {
            node.connections.push(randomNode);
          }
        }
      }
    });

    const drawConnections = () => {
      if (!ctx) return;

      nodes.forEach(node => {
        node.connections.forEach(connectedNode => {
          const dx = node.x - connectedNode.x;
          const dy = node.y - connectedNode.y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < 200) {
            const alpha = 1 - distance / 200;
            ctx.strokeStyle = node.isHub || connectedNode.isHub
              ? `rgba(42, 125, 156, ${alpha * 0.5})`
              : `rgba(100, 100, 100, ${alpha * 0.15})`;
            ctx.lineWidth = node.isHub || connectedNode.isHub ? 0.8 : 0.3;
            ctx.beginPath();
            ctx.moveTo(node.x, node.y);
            ctx.lineTo(connectedNode.x, connectedNode.y);
            ctx.stroke();
          }
        });
      });
    };

    const animate = () => {
      if (!ctx) return;
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw connections first (behind nodes)
      drawConnections();

      // Update and draw nodes
      nodes.forEach(node => {
        node.update();
        node.draw();
      });

      requestAnimationFrame(animate);
    };

    animate();

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const staggerItem = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 50,
        damping: 10
      }
    }
  };

  return (
    <section className="relative h-screen flex items-center overflow-hidden" ref={containerRef}>
      <canvas
        ref={canvasRef}
        className="absolute inset-0 w-full h-full"
        style={{ zIndex: 0 }}
      />

      <motion.div
        className="absolute inset-0 data-flow-lines opacity-20"
        style={{ y, opacity }}
      />

      <motion.div
        className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[500px] h-[300px] rounded-full blob-glow bg-primary/20"
        style={{ zIndex: 1 }}
        animate={{
          scale: [1, 1.05, 1],
          opacity: [0.2, 0.3, 0.2]
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />

      <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <motion.div
          className="max-w-4xl mx-auto text-center"
          variants={staggerContainer}
          initial="hidden"
          animate="visible"
        >
          {/* <motion.div
            className="inline-block px-4 py-1 bg-dark-lighter rounded-full border border-gray-700 text-primary font-medium mb-6"
            variants={staggerItem}
          >
            Enterprise Supply Chain Intelligence
          </motion.div> */}

          <motion.div
            variants={staggerItem}
          >
            <h1 className="text-4xl md:text-6xl lg:text-7xl font-display font-bold mb-6">
              <span className="gradient-text bg-clip-text text-transparent bg-gradient-to-r from-primary via-blue-400 to-primary">Unlock</span> Your Supply Chain Data with Catena
            </h1>
          </motion.div>

          <motion.p
            className="text-xl md:text-2xl text-gray-300 mb-8 max-w-3xl mx-auto"
            variants={staggerItem}
          >
            Providing APIs and infrastructure for supply chain developers to easily build and maintain applications.
          </motion.p>

          <motion.div
            className="flex flex-col sm:flex-row gap-4 justify-center"
            variants={staggerItem}
          >
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
              className="w-full sm:w-auto"
            >
              <button onClick={scrollToContact} className="btn-primary w-full sm:min-w-[160px]">
                Request a Demo
              </button>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
              className="w-full sm:w-auto"
            >
              <button onClick={scrollToSolutions} className="btn-outline w-full sm:min-w-[160px]">
                Explore Platform
              </button>
            </motion.div>
          </motion.div>

          <motion.div
            className="mt-20 flex flex-wrap justify-center gap-10 opacity-80"
            variants={staggerItem}
          >
            {['Telematics', 'Integrations', 'Visibility', 'Insights'].map((offering, index) => (
              <motion.div
                key={offering}
                className="text-gray-400 font-display font-medium text-base uppercase tracking-wider"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.2 + (index * 0.1) }}
              >
                {offering}
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      </div>

      <motion.div
        className="absolute bottom-8 left-0 right-0 flex justify-center z-10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.5, duration: 0.5 }}
        style={{ opacity }}
      >
        <motion.div
          className="flex items-center justify-center w-10 h-10 rounded-full bg-dark-lighter border border-gray-700"
          animate={{
            y: [0, -5, 0],
            boxShadow: [
              "0 0 0 rgba(42, 125, 156, 0.4)",
              "0 0 10px rgba(42, 125, 156, 0.4)",
              "0 0 0 rgba(42, 125, 156, 0.4)"
            ]
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatType: "loop"
          }}
        >
          <motion.div
            animate={{ y: [0, 5, 0] }}
            transition={{ duration: 1.5, repeat: Infinity, repeatType: "loop" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-primary"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 14l-7 7m0 0l-7-7m7 7V3"
              />
            </svg>
          </motion.div>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Hero;
