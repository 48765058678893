/* eslint-disable */

import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

export default function NotFound() {
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.1
      }
    }
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.25, 0.1, 0.25, 1],
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>404 - Page Not Found | Catena</title>
        <meta name="description" content="The page you're looking for couldn't be found." />
      </Helmet>

      <div className="min-h-screen bg-dark flex items-center justify-center relative overflow-hidden py-20">
        {/* Background Elements */}
        <div className="absolute inset-0 grid-pattern opacity-30" />
        <div className="absolute -top-40 right-0 w-[500px] h-[500px] rounded-full blob-glow bg-primary/10" />
        <div className="absolute -bottom-40 left-0 w-[500px] h-[500px] rounded-full blob-glow bg-secondary/10" />

        <motion.div
          className="container mx-auto px-4 relative z-10"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="max-w-3xl mx-auto text-center">
            {/* 404 Illustration */}
            <motion.div
              className="mb-8 relative"
              variants={itemVariants}
            >
              <div className="relative w-64 h-64 mx-auto">
                <img
                  src="/icon-mono.png"
                  alt="404 Illustration"
                  className="w-full h-full object-contain opacity-50"
                />
                <motion.div
                  className="absolute inset-0"
                  animate={{
                    scale: [1, 1.05, 1],
                    opacity: [0.5, 0.7, 0.5]
                  }}
                  transition={{
                    duration: 4,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                >
                  <img
                    src="/icon-mono.png"
                    alt="404 Glow"
                    className="w-full h-full object-contain"
                  />
                </motion.div>
              </div>
            </motion.div>

            {/* Error Message */}
            <motion.div variants={itemVariants}>
              <h1 className="text-7xl md:text-8xl font-display font-bold text-white mb-6">
                404
              </h1>
            </motion.div>

            <motion.div variants={itemVariants}>
              <h2 className="text-2xl md:text-3xl font-display font-semibold text-white mb-4">
                Page Not Found
              </h2>
            </motion.div>

            <motion.div variants={itemVariants}>
              <p className="text-lg text-gray-300 mb-8">
                The page you're looking for doesn't exist or has been moved.
              </p>
            </motion.div>

            {/* Action Buttons */}
            <motion.div
              className="flex flex-col sm:flex-row items-center justify-center gap-4"
              variants={itemVariants}
            >
              <Link
                to="/"
                className="btn-primary px-8 py-3 rounded-full text-white hover:shadow-glow transition duration-300"
              >
                Return Home
              </Link>
            </motion.div>

            {/* Data Flow Animation */}
            <div className="absolute inset-0 pointer-events-none">
              <div className="absolute inset-0 data-flow-lines opacity-10" />
              <motion.div
                className="scanning-line"
                animate={{
                  top: ["0%", "100%", "0%"],
                  opacity: [0, 1, 0]
                }}
                transition={{
                  duration: 4,
                  times: [0, 0.5, 1],
                  repeat: Infinity,
                  repeatDelay: 0.5
                }}
              />
            </div>
          </div>
        </motion.div>
      </div>
    </>
  )
}
