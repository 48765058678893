/* eslint-disable */

import React from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect, useRef, useState, useMemo } from 'react';
import { FaDatabase, FaCreditCard, FaFileInvoiceDollar, FaShieldAlt, FaTruck, FaChartLine, FaTools } from 'react-icons/fa';
import { TbRoute, TbTruckDelivery } from 'react-icons/tb';
import { MdLocalShipping } from 'react-icons/md';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const TelematicsAnimation = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [visibleLogos, setVisibleLogos] = useState<Array<{ logo: string; key: number }>>([]);

  // Memoize the integrations array
  const integrations = useMemo(() => [
    'ADA.png', 'Apollo.png', 'Ascend.png', 'Azuga.png', 'BigRoad.png', 'Blue Ink Tech.png', 'BudgetGPS.png',
    'CyntrX.png', 'DriverTech.png', 'ELD Mandate.png', 'EROAD.png', 'EZLOGZ.png', 'Fleet Complete Hub.png',
    'Fleet Complete Web.png', 'FleetHunt.png', 'FleetSharp.png', 'FleetUp.png', 'Forward Thinking.png', 'Geotab.png',
    'GPS Insight.png', 'GPS Trackit.png', 'HCSS.png', 'HOSConnect.png', 'IntelliShift.png', 'JJ Keller.png',
    'Linxup.png', 'Lytx.png', 'Mapon.png', 'Matrack.png', 'Maven.png', 'Motive.png', 'NERO.png', 'Netradyne.png',
    'NexTraq.png', 'OneStepGPS.png', 'Optima.png', 'Orbcomm.png', 'Phoenix.png', 'Platform Science.png', 'Rand McNally.png',
    'Rastrac.png', 'Samsara.png', 'Selective Drive Fleet.png', 'Surfsight.png', 'Swift.png', 'Switchboard.png', 'Telogis.png',
    'Tenna.png', 'Tive.png', 'Traccar.png', 'TrackEnsure.png', 'truck.png', 'Truckspy.png', 'TruckX.png', 'TT.png',
    'Verizon Connect.png', 'VisTracks.png', 'Webfleet.png', 'Zonar.png', 'Zubie.png'
  ], []);

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setDimensions({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    const updateVisibleLogos = () => {
      const shuffled = [...integrations].sort(() => 0.5 - Math.random());
      const newVisibleLogos = shuffled.slice(0, 20).map(logo => ({
        logo,
        key: Math.random(),
      }));
      setVisibleLogos(newVisibleLogos);
    };

    updateVisibleLogos();
    const interval = setInterval(() => {
      const indexToUpdate = Math.floor(Math.random() * 20);
      setVisibleLogos(prev => {
        const newLogos = [...prev];
        const availableLogos = integrations.filter(logo => !prev.some(item => item.logo === logo));
        if (availableLogos.length > 0) {
          const newLogo = availableLogos[Math.floor(Math.random() * availableLogos.length)];
          newLogos[indexToUpdate] = {
            logo: newLogo,
            key: Math.random(),
          };
        }
        return newLogos;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [integrations]);

  const radius = Math.min(dimensions.width, dimensions.height) * 0.4;
  const center = { x: dimensions.width / 2, y: dimensions.height / 2 };

  const logoPositions = Array(20).fill(0).map((_, index) => {
    const angle = (index / 20) * 2 * Math.PI;
    return {
      x: center.x + radius * Math.cos(angle),
      y: center.y + radius * Math.sin(angle),
      angle: angle,
    };
  });

  return (
    <div ref={containerRef} className="relative w-full h-[500px]">
      <motion.img
        src="/catena-telematics.png"
        alt="Catena Logo"
        className="absolute z-10 brightness-0 invert"
        style={{
          width: '160px',
          height: 'auto',
          top: `${center.y - 70}px`,
          left: `${center.x - 65}px`,
          transform: 'translate(-50%, -50%)',
        }}
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      />

      <AnimatePresence>
        {visibleLogos.map((logoInfo, index) => {
          const position = logoPositions[index];
          const expandDirection = {
            x: Math.cos(position.angle) * 20,
            y: Math.sin(position.angle) * 20,
          };

          return (
            <motion.div
              key={logoInfo.key}
              className="absolute"
              style={{
                top: position.y,
                left: position.x,
                transform: 'translate(-50%, -50%)',
              }}
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
            >
              <motion.img
                src={`/eld/${logoInfo.logo}`}
                alt={`Integration ${index + 1}`}
                className="object-contain"
                style={{
                  width: '36px',
                  height: '36px',
                  filter: 'brightness(0) invert(1)',
                }}
                whileHover={{
                  scale: 2.25,
                  x: expandDirection.x,
                  y: expandDirection.y,
                  transition: {
                    type: 'spring',
                    stiffness: 300,
                    damping: 15,
                    mass: 0.5,
                    duration: 0.9,
                  },
                }}
              />
            </motion.div>
          );
        })}
      </AnimatePresence>

      {Array(100).fill(0).map((_, index) => {
        const position = logoPositions[index % logoPositions.length];
        const initialDelay = Math.random() * 5;
        return (
          <motion.div
            key={`ping-${index}`}
            className="absolute w-3 h-3 bg-primary rounded-full"
            style={{
              top: position.y,
              left: position.x,
              zIndex: 5,
            }}
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: [0, 0.7, 0],
              scale: [0, 1, 0],
              x: [0, center.x - position.x],
              y: [0, center.y - position.y],
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              times: [0, 0.5, 1],
              repeat: Infinity,
              repeatDelay: Math.random() * 5 + 3,
              delay: initialDelay,
            }}
          />
        );
      })}
    </div>
  );
};

const FadeInSection = ({ children }: { children: React.ReactNode }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 }
      }}
      transition={{ duration: 0.5 }}
      className="pointer-events-auto select-text"
    >
      {children}
    </motion.div>
  );
};

interface Feature {
  icon: React.ComponentType<{ className?: string }>;
  title: string;
  description: string;
  items: string[];
}

const CodeSnippet = ({ examples, selectedExample, onSelectExample }: {
  examples: Array<{ title: string; code: string }>;
  selectedExample: number;
  onSelectExample: (index: number) => void;
}) => {
  return (
    <div className="bg-dark rounded-lg overflow-hidden border border-gray-800 pointer-events-auto">
      <div className="flex bg-dark-lighter overflow-x-auto">
        {examples.map((example, index) => (
          <button
            key={index}
            className={`px-6 py-3 text-sm font-medium whitespace-nowrap transition-colors duration-200 pointer-events-auto ${
              selectedExample === index
                ? 'bg-primary text-white'
                : 'text-gray-400 hover:text-white hover:bg-dark'
            }`}
            onClick={() => onSelectExample(index)}
            type="button"
          >
            {example.title}
          </button>
        ))}
      </div>
      <div className="relative">
        <pre className="p-6 text-sm overflow-x-auto select-text pointer-events-auto">
          <code className="text-gray-300 font-mono select-text">
            {examples[selectedExample].code}
          </code>
        </pre>
        <div className="absolute top-4 right-4 flex space-x-2 pointer-events-none">
          <div className="w-3 h-3 rounded-full bg-red-500" />
          <div className="w-3 h-3 rounded-full bg-yellow-500" />
          <div className="w-3 h-3 rounded-full bg-green-500" />
        </div>
      </div>
    </div>
  );
};

const FeatureCard = ({ feature }: { feature: Feature }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <motion.div
      className="relative p-6 rounded-lg cursor-pointer transition-all duration-300 bg-dark-lighter text-gray-300 hover:bg-dark border border-gray-800 hover:border-primary group"
      onClick={() => setIsExpanded(!isExpanded)}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
    >
      <div className="flex items-center mb-4">
        <feature.icon className="text-2xl mr-3 text-primary group-hover:text-white transition-colors duration-200" />
        <h3 className="text-lg font-display font-bold">{feature.title}</h3>
      </div>
      <p className="text-sm mb-4 text-gray-400">{feature.description}</p>
      <AnimatePresence initial={false}>
        {isExpanded && (
          <motion.ul
            className="space-y-3 border-t border-gray-800 pt-4 mt-4"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
          >
            {feature.items.map((item, index) => (
              <motion.li
                key={index}
                className="flex items-center text-sm"
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.05 }}
              >
                <span className="text-primary mr-3">✓</span>
                {item}
              </motion.li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
      <div className="absolute top-4 right-4">
        <motion.div
          animate={{ rotate: isExpanded ? 180 : 0 }}
          transition={{ duration: 0.2 }}
        >
          <svg
            className="w-5 h-5 text-gray-500 group-hover:text-white transition-colors duration-200"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </motion.div>
      </div>
    </motion.div>
  );
};

const TelematicsPage = () => {
  const [selectedApiExample, setSelectedApiExample] = useState(0);

  const apiExamples = [
    {
      title: "Vehicle Data",
      code: `GET /vehicle/:id

{
  "id": 103,
  "time": "2022-07-27T07:06:25.000Z",
  "tsp_source": "samsara",
  "oem": "Freightliner",
  "model": "Cascadia",
  "model_year": 2018,
  "vin": "3AKJHHDR9JSJV5535",
  "location": {
    "latitude": 122.142,
    "longitude": -93.343,
    "address": "16 Oak St, Pasadena, CA 91103"
  },
  "speed": 48.3,
  "odometer": 140100
}`
    },
    {
      title: "Asset Data",
      code: `GET /asset/:asset_id

{
  "id": "asset_12345",
  "make": "Great Dane",
  "model": "Freedom LT",
  "model_year": 2020,
  "location": {
    "latitude": 34.1478,
    "longitude": -118.1445,
  },
  "odometer": 50000,
  "tire_pressure": {
    "front_left": 100,
    "front_right": 102,
    "rear_left": 98,
    "rear_right": 101
  }
}`
    },
    {
      title: "Fleet Data",
      code: `GET /fleet/:fleet_id

{
  "id": "fleet_67890",
  "carrier_name": "ABC Trucking Co.",
  "office_address": {
    "street": "456 Transport Ave",
    "city": "Los Angeles",
    "state": "CA",
    "zip": "90001"
  },
  "dot_number": "US DOT 1234567",
  "total_vehicles": 50,
  "total_drivers": 60,
  "active_vehicles": 45,
  "inactive_vehicles": 5
}`
    },
    {
      title: "Driver Data",
      code: `GET /driver/:driver_id

{
  "id": "driver_789",
  "name": "John Doe",
  "phone_number": "+1 (555) 123-4567",
  "carrier_info": {
    "carrier_id": "carrier_456",
    "carrier_name": "ABC Trucking Co."
  },
  "status": "On Duty",
  "license": {
    "number": "D1234567",
    "state": "CA"
  },
  "hours_of_service": {
    "drive_time_remaining": 420,
    "on_duty_time_remaining": 600
  }
}`
    }
  ];

  const features: Feature[] = [
    {
      icon: FaDatabase,
      title: "Core Benefits",
      description: "Universal API platform connecting to all major ELD providers",
      items: ["Universal API for 60+ ELD devices", "Seamless fleet onboarding", "UIs for fleet management", "System of record"]
    },
    {
      icon: TbTruckDelivery,
      title: "TMS",
      description: "Enhance your transportation management system with real-time data",
      items: ["Customizable, real-time fleet visibility", "Proof-of-delivery validation", "Real-time HOS events and availability", "IFTA and other workflow automations"]
    },
    {
      icon: FaCreditCard,
      title: "Fuel Cards",
      description: "Streamline fuel card operations and prevent fraud",
      items: ["Real-time fuel-up detection", "Fuel pump fraud detection", "Transaction validation and logging", "Real-time geofencing and webhooks"]
    },
    {
      icon: FaFileInvoiceDollar,
      title: "Factoring",
      description: "Automate payment processes and reduce risk",
      items: ["Proof-of-delivery validation", "Payout automation", "Fraud detection", "Real-time geofencing and webhooks"]
    },
    {
      icon: FaShieldAlt,
      title: "Insurers",
      description: "Better risk assessment and automated compliance",
      items: ["Live safety event recording", "Historical driving records and analytics", "Driver records for premium optimization", "Integrations with dashcams and ELDs"]
    },
    {
      icon: TbRoute,
      title: "Logistics",
      description: "Optimize routes and improve delivery efficiency",
      items: ["Load optimization", "Route optimization", "Proof-of-delivery validation", "Real-time geofencing and webhooks"]
    },
    {
      icon: FaTruck,
      title: "Loadboards",
      description: "Streamline carrier matching and prevent fraud",
      items: ["Faster, more reliable carrier onboarding", "Fraud prevention and double brokering detection", "Better load matching / more volume", "Automate load tracking"]
    },
    {
      icon: FaTools,
      title: "Safety & Maintenance",
      description: "Proactive fleet maintenance and safety management",
      items: ["Proactive driver safety and risk reduction", "Predictive maintenance to prevent breakdowns", "Data driven repair and parts optimization", "Seamless integration with fleet management"]
    },
    {
      icon: FaChartLine,
      title: "Visibility Software",
      description: "End-to-end supply chain visibility",
      items: ["Real-time visibility into truck locations", "Live GPS, HOS, and telematics data", "Eliminate manual check calls", "Automate POD verification"]
    }
  ];

  const steps = [
    { title: "Seamlessly onboard your fleets", description: "Login and onboard with easy to use, client-side interface." },
    { title: "Leverage real-time insights", description: "Customize alerts with callbacks for instant data updates and decision-making." },
    { title: "Ensure reliability and security", description: "Benefit from our fault-tolerant, high-availability infrastructure and behind-the-scenes API monitoring." },
    { title: "Focus on your core product", description: "Let us maintain, manage, and update your integrations with white-glove technical support." }
  ];

  const scrollToContact = () => {
    // Navigate to home page first if needed
    if (!window.location.pathname.includes('solutions')) {
      window.location.href = '/#contact';
      return;
    }
    // Otherwise scroll to contact section
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Helmet>
        <title>Catena Telematics | Universal Fleet Data Platform</title>
        <meta name="description" content="Universal middle mile tracking and fleet operations platform that unifies your telematics data across carriers." />
        <link rel="icon" href="/icon-mono-small-dark.png" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://catenaclearing.io/solutions/telematics" />
        <meta property="og:title" content="Catena Telematics | Universal Fleet Data Platform" />
        <meta property="og:description" content="Universal middle mile tracking and fleet operations platform that unifies your telematics data across carriers." />
        <meta property="og:image" content="https://catenaclearing.io/logo-square.png" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://catenaclearing.io/solutions/telematics" />
        <meta property="twitter:title" content="Catena Telematics | Universal Fleet Data Platform" />
        <meta property="twitter:description" content="Universal middle mile tracking and fleet operations platform that unifies your telematics data across carriers." />
        <meta property="twitter:image" content="https://catenaclearing.io/logo-square.png" />
      </Helmet>

      <div className="min-h-screen bg-dark select-text">
        {/* Hero Section */}
        <section className="pt-32 pb-20 relative overflow-hidden pointer-events-auto">
          <div className="absolute inset-0 grid-pattern opacity-20 pointer-events-none" />
          <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-b from-transparent to-dark-lighter pointer-events-none" />
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative">
            <motion.div
              className="max-w-4xl mx-auto text-center pointer-events-auto select-text"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <h1 className="text-4xl md:text-6xl font-display font-bold mb-6">
                Catena <span className="gradient-text bg-clip-text text-transparent bg-gradient-to-r from-primary via-blue-400 to-primary">Telematics</span>
              </h1>
              <p className="text-xl text-gray-300 mb-12">
                Universal middle mile tracking and fleet operations platform that unifies your telematics data across carriers.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <Link
                  to="/#contact"
                  className="btn-primary px-8 py-3"
                >
                  Request a Demo
                </Link>
              </div>
            </motion.div>
          </div>
        </section>

        {/* Animation Section */}
        <section className="py-20 relative overflow-hidden">
          <div className="absolute top-0 left-0 right-0 h-32 bg-gradient-to-t from-transparent to-dark-lighter pointer-events-none" />
          <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-b from-transparent to-dark-lighter pointer-events-none" />
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
              <div className="w-full lg:w-1/2">
                <TelematicsAnimation />
              </div>
              <div className="w-full lg:w-1/2">
                <FadeInSection>
                  <h2 className="text-3xl font-display font-bold mb-6">
                    Connect with 60+ Telematics Providers
                  </h2>
                  <p className="text-xl text-gray-300 mb-6">
                    Catena Telematics eliminates data fragmentation by providing a universal API that connects with all major ELD and telematics providers.
                  </p>
                  <ul className="space-y-4">
                    <li className="flex items-center text-gray-300">
                      <span className="text-primary mr-3">✓</span>
                      Real-time location tracking and HOS data
                    </li>
                    <li className="flex items-center text-gray-300">
                      <span className="text-primary mr-3">✓</span>
                      Unified dashboard for fleet management
                    </li>
                    <li className="flex items-center text-gray-300">
                      <span className="text-primary mr-3">✓</span>
                      Automated compliance monitoring
                    </li>
                    <li className="flex items-center text-gray-300">
                      <span className="text-primary mr-3">✓</span>
                      Seamless integration with existing systems
                    </li>
                  </ul>
                </FadeInSection>
              </div>
            </div>
          </div>
        </section>

        {/* API Examples Section */}
        <section className="py-20 bg-dark-lighter relative overflow-hidden">
          <div className="absolute inset-0 grid-pattern opacity-10 pointer-events-none" />
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <FadeInSection>
              <h2 className="text-3xl font-display font-bold mb-12 text-center select-text">
                Simple and Powerful API
              </h2>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="flex flex-col justify-center select-text">
                  <p className="text-xl text-gray-300 mb-6 select-text">
                    Our API provides easy access to real-time data across various aspects of fleet operations, enabling you to build powerful applications and integrations.
                  </p>
                  <p className="text-xl text-gray-300 mb-6 select-text">
                    With only a few API calls, you can retrieve comprehensive information on vehicles, assets, fleets, drivers, and more!
                  </p>
                </div>
                <div className="w-full">
                  <div className="bg-dark rounded-lg overflow-hidden border border-gray-800">
                    <div className="flex bg-dark-lighter overflow-x-auto">
                      {apiExamples.map((example, index) => (
                        <button
                          key={index}
                          className={`px-6 py-3 text-sm font-medium whitespace-nowrap transition-colors duration-200 z-10 ${
                            selectedApiExample === index
                              ? 'bg-primary text-white'
                              : 'text-gray-400 hover:text-white hover:bg-dark'
                          }`}
                          onClick={() => setSelectedApiExample(index)}
                          type="button"
                        >
                          {example.title}
                        </button>
                      ))}
                    </div>
                    <div className="relative">
                      <pre className="p-6 text-sm overflow-x-auto select-text h-[430px]">
                        <code className="text-gray-300 font-mono select-text">
                          {apiExamples[selectedApiExample].code}
                        </code>
                      </pre>
                      <div className="absolute top-4 right-4 flex space-x-2 pointer-events-none">
                        <div className="w-3 h-3 rounded-full bg-red-500" />
                        <div className="w-3 h-3 rounded-full bg-yellow-500" />
                        <div className="w-3 h-3 rounded-full bg-green-500" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </FadeInSection>
          </div>
        </section>

        {/* Industry Solutions Section */}
        <section className="py-20 relative overflow-hidden">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <FadeInSection>
              <h2 className="text-3xl font-display font-bold mb-4 text-center">
                Industry Solutions
              </h2>
              <p className="text-xl text-gray-300 mb-12 text-center max-w-3xl mx-auto">
                Discover how Catena&apos;s universal telematics platform can transform your specific industry vertical
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {features.map((feature, index) => (
                  <FeatureCard
                    key={index}
                    feature={feature}
                  />
                ))}
              </div>
            </FadeInSection>
          </div>
        </section>

        {/* Steps Section */}
        <section className="py-20 bg-dark-lighter relative overflow-hidden pointer-events-auto">
          <div className="absolute inset-0 grid-pattern opacity-10 pointer-events-none" />
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <FadeInSection>
              <h2 className="text-3xl font-display font-bold mb-12 text-center pointer-events-auto select-text">
                Getting Started is Easy
              </h2>
              <div className="relative">
                <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-primary opacity-20" />
                {steps.map((step, index) => (
                  <div key={index} className={`flex ${index % 2 === 0 ? 'flex-row-reverse' : 'flex-row'} items-center mb-12`}>
                    <div className="w-1/2" />
                    <div className="w-1/2 p-4">
                      <div className="bg-dark p-6 rounded-lg border border-gray-800 hover:border-primary transition-colors duration-300">
                        <h3 className="text-xl font-display font-bold mb-3">{step.title}</h3>
                        <p className="text-gray-300">{step.description}</p>
                      </div>
                    </div>
                    <div className="absolute left-1/2 transform -translate-x-1/2 w-4 h-4 rounded-full bg-primary" />
                  </div>
                ))}
              </div>
            </FadeInSection>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-20">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <motion.div
              className="max-w-3xl mx-auto text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              <h2 className="text-3xl font-display font-bold mb-6">
                Ready to Unify Your Fleet Data?
              </h2>
              <p className="text-gray-300 mb-8">
                Get started with Catena Telematics today and transform your fleet operations.
              </p>
              <Link
                to="/#contact"
                className="btn-primary px-8 py-3"
              >
                Request a Demo
              </Link>
            </motion.div>
          </div>
        </section>
      </div>
    </>
  );
};

export default TelematicsPage;
