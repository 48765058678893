import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');

    try {
      // TODO: Implement login logic
      navigate('/dashboard');
    } catch (err) {
      setError('Invalid email or password');
    }
  };

  return (
    <>
      <Helmet>
        <title>Login | Catena</title>
        <meta name="description" content="Log in to your Catena account to access your supply chain data and analytics." />
        <link rel="icon" href="/icon-mono-small-dark.png" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://catenaclearing.io/login" />
        <meta property="og:title" content="Login | Catena" />
        <meta property="og:description" content="Log in to your Catena account to access your supply chain data and analytics." />
        <meta property="og:image" content="https://catenaclearing.io/logo-square.png" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://catenaclearing.io/login" />
        <meta property="twitter:title" content="Login | Catena" />
        <meta property="twitter:description" content="Log in to your Catena account to access your supply chain data and analytics." />
        <meta property="twitter:image" content="https://catenaclearing.io/logo-square.png" />
      </Helmet>

      <div className="min-h-screen flex items-center justify-center bg-dark">
        <div className="max-w-md w-full mx-4">
          <div className="text-center mb-8">
            <Link to="/" className="inline-block">
              <img src="/icon-mono.png" alt="Catena Logo" className="h-12 w-12 mx-auto mb-4" />
            </Link>
            <h2 className="text-3xl font-display font-bold text-white">Welcome back</h2>
            <p className="mt-2 text-gray-400">Please sign in to your account</p>
          </div>

          <div className="bg-dark-lighter rounded-xl p-8 shadow-lg border border-gray-800">
            <form onSubmit={handleSubmit} className="space-y-6">
              {error && (
                <div className="p-4 bg-red-500/10 border border-red-500/20 rounded-lg text-red-500 text-sm">
                  {error}
                </div>
              )}

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-2">
                  Email address
                </label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="w-full p-3 rounded-lg bg-dark border border-gray-700 text-white placeholder-gray-400 focus:border-primary focus:ring-1 focus:ring-primary transition duration-200"
                  placeholder="you@company.com"
                />
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-300 mb-2">
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="w-full p-3 rounded-lg bg-dark border border-gray-700 text-white placeholder-gray-400 focus:border-primary focus:ring-1 focus:ring-primary transition duration-200"
                  placeholder="••••••••"
                />
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-700 bg-dark text-primary focus:ring-primary"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-400">
                    Remember me
                  </label>
                </div>

                <Link
                  to="/forgot-password"
                  className="text-sm font-medium text-primary hover:text-primary-light transition-colors duration-200"
                >
                  Forgot password?
                </Link>
              </div>

              <button
                type="submit"
                className="w-full btn-primary py-3"
              >
                Sign in
              </button>
            </form>

            <div className="mt-6 text-center text-sm">
              <span className="text-gray-400">Don&apos;t have an account?</span>{' '}
              <Link
                to="/#contact"
                className="font-medium text-primary hover:text-primary-light transition-colors duration-200"
              >
                Contact us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
