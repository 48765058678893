import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

interface BlogPost {
  id: string;
  title: string;
  description: string;
  date: string;
  author: string;
  image: string;
  slug: string;
  category: string;
}

const Blog = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch('/content/blog/index.json');
        const data = await response.json();
        setPosts(data.posts);
      } catch (error) {
        console.error('Error loading blog posts:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-dark flex items-center justify-center">
        <div className="text-gray-300">Loading...</div>
      </div>
    );
  }

  const featuredPost = posts[0]; // Most recent post
  const otherPosts = posts.slice(1); // Rest of the posts

  return (
    <>
      <Helmet>
        <title>Blog | Catena</title>
        <meta name="description" content="Read the latest insights about supply chain technology, industry trends, and best practices." />
        <link rel="icon" href="/icon-mono-small-dark.png" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://catenaclearing.io/blog" />
        <meta property="og:title" content="Blog | Catena" />
        <meta property="og:description" content="Read the latest insights about supply chain technology, industry trends, and best practices." />
        <meta property="og:image" content="https://catenaclearing.io/logo-square.png" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://catenaclearing.io/blog" />
        <meta property="twitter:title" content="Blog | Catena" />
        <meta property="twitter:description" content="Read the latest insights about supply chain technology, industry trends, and best practices." />
        <meta property="twitter:image" content="https://catenaclearing.io/logo-square.png" />
      </Helmet>

      <div className="bg-dark min-h-screen">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24">
          <motion.div
            className="text-center mb-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h1 className="text-4xl sm:text-5xl font-display font-bold text-white mb-6">
              Catena <span className="gradient-text bg-clip-text text-transparent bg-gradient-to-r from-primary via-blue-400 to-primary">Blog</span>
            </h1>
            <p className="text-lg text-gray-300 max-w-2xl mx-auto">
              Insights, updates, and technical deep dives into supply chain technology.

            </p>
          </motion.div>

          {/* Featured Post - Hidden on mobile */}
          <motion.div
            className="mb-16 hidden md:block"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <Link
              to={`/blog/${featuredPost.slug}`}
              className="group relative block bg-dark-lighter rounded-xl overflow-hidden border border-gray-800 hover:border-primary transition-colors duration-300"
            >
              <div className="relative aspect-w-16 aspect-h-7">
                <img
                  src={featuredPost.image}
                  alt={featuredPost.title}
                  className="w-full h-full object-cover object-center"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-dark via-dark/50 to-transparent opacity-80 group-hover:opacity-90 transition-opacity duration-500" />
                <div className="absolute inset-0 flex flex-col justify-end p-8">
                  <div className="flex items-center text-sm text-gray-200 mb-4 font-medium">
                    <span className="px-3 py-1 rounded-full bg-primary/40 text-white text-sm font-semibold">
                      {featuredPost.category}
                    </span>
                    <span className="mx-2 text-white/90">·</span>
                    <time dateTime={featuredPost.date} className="text-white/90">{featuredPost.date}</time>
                    <span className="mx-2 text-white/90">·</span>
                    <span className="text-white/90">{featuredPost.author}</span>
                  </div>
                  <h2 className="text-4xl font-display font-semibold text-white mb-4 group-hover:text-primary transition-colors duration-300">
                    {featuredPost.title}
                  </h2>
                  <p className="text-gray-300 text-lg max-w-3xl">
                    {featuredPost.description}
                  </p>
                  <div className="mt-6 flex items-center text-primary">
                    <span className="text-sm font-medium">Read more</span>
                    <svg className="w-4 h-4 ml-2 transition-transform duration-300 group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                  </div>
                </div>
              </div>
            </Link>
          </motion.div>

          {/* Posts Grid - Shows all posts on mobile, other posts on larger screens */}
          <motion.div
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            {/* On mobile, show all posts. On desktop, show only other posts */}
            {[...(window.innerWidth < 768 ? [featuredPost] : []), ...otherPosts].map((post) => (
              <Link
                key={post.id}
                to={`/blog/${post.slug}`}
                className="group bg-dark-lighter rounded-lg overflow-hidden border border-gray-800 hover:border-primary transition-colors duration-300"
              >
                <div className="relative h-48">
                  <img
                    src={post.image}
                    alt={post.title}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                </div>
                <div className="p-6">
                  <div className="flex items-center space-x-4 mb-4">
                    <span className="px-3 py-1 rounded-full bg-primary/20 text-primary text-sm">
                      {post.category}
                    </span>
                    <span className="text-gray-400 text-sm">{post.date}</span>
                  </div>
                  <h3 className="text-xl font-display font-bold mb-3 text-white group-hover:text-primary transition-colors duration-300">
                    {post.title}
                  </h3>
                  <p className="text-gray-300 mb-4 line-clamp-2">
                    {post.description}
                  </p>
                  <div className="flex items-center text-gray-400">
                    <span>By {post.author}</span>
                  </div>
                </div>
              </Link>
            ))}
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Blog;
