/* eslint-disable */

import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

interface FeatureCardProps {
  title: string;
  description: string;
  icon: string;
  iconColor: string;
  delay: number;
  index: number;
}

// Animation variants for staggered reveals
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.1,
      duration: 0.4,
      ease: "easeOut"
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: [0.25, 0.1, 0.25, 1],
    }
  }
};

const BeliefCard = ({ title, description, icon, iconColor, delay, index }: FeatureCardProps) => {
  return (
    <motion.div
      className="bg-dark-lighter rounded-lg p-6 shadow-card border border-gray-800 hover:border-primary/30 transition-all duration-300 hover:shadow-glow relative overflow-hidden group"
      variants={itemVariants}
    >
      <div className="absolute -right-10 -top-10 w-40 h-40 bg-primary/5 rounded-full blur-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-500" />

      <div className={`w-14 h-14 flex items-center justify-center rounded-md mb-5 ${iconColor} bg-opacity-10 relative z-10`}>
        <span className={`text-2xl ${iconColor}`}>{icon}</span>
      </div>

      <h3 className="text-xl font-display font-semibold mb-3 text-white">{title}</h3>
      <p className="text-gray-300 leading-relaxed">{description}</p>
    </motion.div>
  );
};

const SolutionCard = ({ title, description, icon, iconColor, benefits }: { title: string; description: string; icon: string; iconColor: string; benefits: { shipper: string; carrier: string; } }) => {
  return (
    <motion.div
      className="bg-gradient-to-br from-dark-lighter to-dark-lightest rounded-xl p-8 shadow-lg border border-gray-800"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      whileHover={{ y: -5 }}
      transition={{ duration: 0.3 }}
    >
      <div className={`inline-flex items-center justify-center p-3 rounded-lg ${iconColor} bg-opacity-10 mb-6`}>
        <span className="text-3xl">{icon}</span>
      </div>

      <h3 className="text-2xl font-display font-bold mb-4 text-white">{title}</h3>
      <p className="text-gray-300 mb-6">{description}</p>

      <div className="space-y-6">
        <div>
          <div className="flex items-center mb-3">
            <span className="text-lg mr-2">🏢</span>
            <h4 className="text-sm font-semibold uppercase tracking-wider text-primary">For Shippers</h4>
          </div>
          <p className="text-gray-400 text-sm">{benefits.shipper}</p>
        </div>

        <div>
          <div className="flex items-center mb-3">
            <span className="text-lg mr-2">🚛</span>
            <h4 className="text-sm font-semibold uppercase tracking-wider text-accent-orange">For Carriers</h4>
          </div>
          <p className="text-gray-400 text-sm">{benefits.carrier}</p>
        </div>
      </div>
    </motion.div>
  );
};

const Features = () => {
  const beliefs = [
    {
      title: "Unified Data Integration",
      description: "Industry leaders should focus on solving real-world logistics challenges—not have inefficient IT teams struggling with data integration.",
      icon: "🚛",
      iconColor: "text-primary",
      delay: 0.1
    },
    {
      title: "Data Fragmentation",
      description: "Data fragmentation is the biggest bottleneck preventing supply chains from realizing the full potential of data.",
      icon: "📊",
      iconColor: "text-accent-orange",
      delay: 0.2
    },
    {
      title: "Data-Driven Decisions",
      description: "Data should power every decision in global supply chains, ensuring interoperability across vendors to optimize demand planning, asset monitoring, and resilience.",
      icon: "🔗",
      iconColor: "text-secondary",
      delay: 0.3
    }
  ];

  const solutions = [
    {
      title: "Catena Telematics",
      description: "Unified, real-time data to optimize shipment scheduling and reduce delays.",
      icon: "📡",
      iconColor: "text-accent-blue",
      benefits: {
        shipper: "Limited real-time shipment visibility leads to delays and inefficiencies. Our solution provides unified, real-time data to optimize shipment scheduling.",
        carrier: "Achieve seamless fleet operational reporting across carriers by unifying TSPs & ELDs."
      }
    },
    {
      title: "Catena Connect",
      description: "Integrate and enrich data flows from multiple sources directly into your systems of record.",
      icon: "🔄",
      iconColor: "text-primary",
      benefits: {
        shipper: "Siloed inventory and transportation systems make coordination difficult. We integrate and enrich data flows from multiple sources.",
        carrier: "Syncs load ETAs and updates directly into ERP, TMS, and BI systems."
      }
    },
    {
      title: "Catena Gateway",
      description: "AI-powered data integration that unifies disparate supply chain data.",
      icon: "🌐",
      iconColor: "text-secondary",
      benefits: {
        shipper: "Inaccurate forecasting and demand planning disrupt supply chain flow. Our AI-powered integration unifies data into your ERP, OMS, IMS, and more.",
        carrier: "AI-powered data transformation for seamless fleet operations and financial optimization."
      }
    }
  ];

  return (
    <>
      <section className="section bg-dark relative overflow-hidden">
        <div className="absolute inset-0 grid-pattern opacity-30" />

        <div
          className="absolute -top-40 left-0 w-[500px] h-[500px] rounded-full blob-glow bg-primary/10"
          style={{ zIndex: 1 }}
        />

        <div className="container mx-auto relative z-10">
          <motion.div
            className="max-w-3xl mx-auto text-center mb-16"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              type: "spring",
              stiffness: 60,
              damping: 20
            }}
            viewport={{ once: true, amount: 0.3 }}
          >
            <div className="inline-block px-4 py-1 bg-dark-lighter rounded-full border border-gray-700 text-primary font-medium mb-4">
              Our Beliefs
            </div>
            <h2 className="text-3xl md:text-4xl font-display font-bold mb-6">
              Transforming Supply Chain Data Into Value
            </h2>

            <p className="text-lg text-gray-300">
              We believe in empowering businesses with seamless data integration and intelligent solutions that drive real-world logistics excellence.
            </p>
          </motion.div>

          <motion.div
            className="grid grid-cols-1 md:grid-cols-3 gap-6 lg:gap-8"
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.1 }}
          >
            {beliefs.map((belief, index) => (
              <BeliefCard
                key={index}
                title={belief.title}
                description={belief.description}
                icon={belief.icon}
                iconColor={belief.iconColor}
                delay={belief.delay}
                index={index}
              />
            ))}
          </motion.div>
        </div>
      </section>

      <section id="solutions" className="section bg-gradient-to-b from-dark to-dark-lighter relative overflow-hidden">
        <div className="absolute inset-0 data-flow-lines opacity-10" />

        <div className="container mx-auto relative z-10">
          <motion.div
            className="max-w-3xl mx-auto text-center mb-16"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true, amount: 0.3 }}
          >
            <div className="inline-block px-4 py-1 bg-dark-lighter rounded-full border border-gray-700 text-primary font-medium mb-4">
              Our Solutions
            </div>
            <h2 className="text-3xl md:text-4xl font-display font-bold mb-6">
              Powerful Solutions for Every Stakeholder
            </h2>

            <p className="text-lg text-gray-300">
              Tailored solutions that address the unique challenges faced by both shippers and carriers.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {solutions.map((solution, index) => (
              <SolutionCard key={index} {...solution} />
            ))}
          </div>

          <motion.div
            className="mt-24 relative"
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              type: "spring",
              stiffness: 50,
              damping: 20,
              delay: 0.2
            }}
            viewport={{ once: true, amount: 0.2 }}
          >
            <div className="relative rounded-xl border border-gray-800/30 shadow-lg overflow-hidden">
              {/* Simple, elegant background fade */}
              <div className="absolute inset-0">
                <div className="absolute inset-0 bg-gradient-to-br from-dark-lighter/50 to-dark" />
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,_rgba(42,125,156,0.1),_transparent_50%)]" />
              </div>

              {/* Content */}
              <div className="relative p-8 lg:p-10">
                <div className="grid md:grid-cols-2 gap-10 items-center">
                  <motion.div
                    initial={{ opacity: 0, x: -30 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.5, duration: 0.7 }}
                    viewport={{ once: true }}
                  >
                    <div className="text-xs font-semibold uppercase tracking-wider text-secondary mb-2">Featured Solution</div>
                    <h3 className="text-2xl font-display font-bold mb-4">
                      Catena Telematics
                    </h3>
                    <p className="text-gray-300 mb-6 leading-relaxed">
                      A universal API that streamlines data access from onboard telematics for tracking the middle mile. Unify your telematics data across carriers, enabling real-time visibility and operational efficiency for TMS updates, optimization tools, factoring, insurance, and more.
                    </p>
                    <ul className="space-y-3 mb-8">
                      {[
                        "Real-time location tracking with speed, mileage, and automated ETA notifications",
                        "Historical route reconstruction with common data model for system of record",
                        "Streamlined proof-of-delivery for efficient payroll and invoicing",
                        "Transaction data for fueling models, toll-road payments, and IFTA calculations",
                        "Unified TSP & ELD data across carriers for seamless fleet operations"
                      ].map((item, index) => (
                        <motion.li
                          key={index}
                          className="flex items-start"
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          transition={{ delay: 0.6 + (index * 0.1) }}
                          viewport={{ once: true }}
                        >
                          <div className="flex-shrink-0 w-5 h-5 rounded-full bg-secondary/20 flex items-center justify-center mt-0.5">
                            <svg className="w-3 h-3 text-secondary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                            </svg>
                          </div>
                          <span className="ml-3 text-gray-300">{item}</span>
                        </motion.li>
                      ))}
                    </ul>
                    <Link
                      to="/solutions/telematics"
                      className="btn-secondary inline-flex items-center"
                    >
                      Learn More About Telematics
                      <svg className="ml-2 w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </Link>
                  </motion.div>
                  <motion.div
                    className="relative h-80 rounded-lg overflow-hidden border border-gray-700"
                    initial={{ opacity: 0, scale: 0.9 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.7, duration: 0.5 }}
                    viewport={{ once: true }}
                  >
                    <img
                      src="/map.png"
                      alt="Map background"
                      className="absolute inset-0 w-full h-full object-cover opacity-40"
                    />
                    <div className="absolute inset-0 bg-dark-lighter bg-opacity-50" />
                    <div className="absolute inset-0 data-flow-lines opacity-20" />
                    <motion.div
                      className="scanning-line"
                      animate={{
                        top: ["0%", "100%", "0%"],
                        opacity: [0, 1, 0]
                      }}
                      transition={{
                        duration: 4,
                        times: [0, 0.5, 1],
                        repeat: Infinity,
                        repeatDelay: 0.5
                      }}
                    />
                    <div className="relative h-full w-full flex items-center justify-center">
                      <div className="absolute top-1/3 left-1/4">
                        <div className="pulse-ring"></div>
                        <div className="map-dot bg-accent-blue"></div>
                      </div>
                      <div className="absolute top-1/2 right-1/3">
                        <div className="pulse-ring"></div>
                        <div className="map-dot bg-accent-blue"></div>
                      </div>
                      <div className="absolute bottom-1/3 left-1/2">
                        <div className="pulse-ring"></div>
                        <div className="map-dot bg-accent-blue"></div>
                      </div>
                      <div className="absolute top-1/4 right-1/4">
                        <div className="pulse-ring"></div>
                        <div className="map-dot bg-accent-blue"></div>
                      </div>
                      <div className="absolute bottom-1/4 right-1/2">
                        <div className="pulse-ring"></div>
                        <div className="map-dot bg-accent-blue"></div>
                      </div>
                      <div className="absolute top-2/3 right-1/4">
                        <div className="pulse-ring"></div>
                        <div className="map-dot bg-accent-blue"></div>
                      </div>
                      <motion.div
                        className="p-4 bg-dark-lightest bg-opacity-90 backdrop-blur-sm rounded-md border border-gray-700 shadow-lg"
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 1, duration: 0.5 }}
                      >
                        <div className="text-xl font-display font-bold text-secondary">Telematics API</div>
                        <div className="text-sm text-gray-400">Universal middle mile tracking</div>
                      </motion.div>
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
    </>
  );
};

export default Features;
