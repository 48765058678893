import { Helmet } from 'react-helmet-async'
import Hero from '@/components/Hero'
import Features from '@/components/Features'
import CTA from '@/components/CTA'
import NetworkVisualizer from '@/components/NetworkVisualizer'

export default function Home() {
  return (
    <div className="min-h-screen bg-dark text-white">
      <Helmet>
        <title>Catena | Unlock Your Supply Chain Data</title>
        <meta name="description" content="Providing APIs and infrastructure for supply chain developers to easily build and maintain applications." />
        <link rel="icon" href="/icon-mono-small-dark.png" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://catenaclearing.io/" />
        <meta property="og:title" content="Catena | Unlock Your Supply Chain" />
        <meta property="og:description" content="Providing APIs and infrastructure for supply chain developers to easily build and maintain applications." />
        <meta property="og:image" content="https://catenaclearing.io/logo-square.png" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://catenaclearing.io/" />
        <meta property="twitter:title" content="Catena | Unlock Your Supply Chain" />
        <meta property="twitter:description" content="Providing APIs and infrastructure for supply chain developers to easily build and maintain applications." />
        <meta property="twitter:image" content="https://catenaclearing.io/logo-square.png" />
      </Helmet>

      <main>
        <Hero />
        <Features />
        <NetworkVisualizer />
        <CTA />
      </main>
    </div>
  )
}
