import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

interface DropdownProps {
  items: {
    name: string;
    href: string;
    description?: string;
  }[];
  show: boolean;
}

const Dropdown = ({ items, show }: DropdownProps) => (
  <AnimatePresence>
    {show && (
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 10 }}
        transition={{ duration: 0.2 }}
        className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 w-screen max-w-md px-2"
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="relative bg-dark-lighter">
            <div className="max-w-7xl mx-auto">
              <div className="divide-y divide-gray-800">
                {items.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="p-4 flex items-start hover:bg-dark-lightest transition-colors duration-200"
                  >
                    <div>
                      <p className="text-base font-medium text-white">{item.name}</p>
                      {item.description && (
                        <p className="mt-1 text-sm text-gray-400">{item.description}</p>
                      )}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    )}
  </AnimatePresence>
);

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [solutionsOpen, setSolutionsOpen] = useState(false);
  const [companyOpen, setCompanyOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const solutions = [
    {
      name: 'Telematics',
      href: '/solutions/telematics',
      description: 'Universal middle mile tracking and fleet operations'
    },
    {
      name: 'Gateway',
      href: '/solutions/gateway',
      description: 'AI-powered data integration and transformation'
    },
    {
      name: 'Connect',
      href: '/solutions/connect',
      description: 'Seamless data flows and system integration'
    }
  ];

  const company = [
    {
      name: 'Team',
      href: '/company/team',
      description: 'Meet the people building Catena'
    },
    {
      name: 'Blog',
      href: '/company/blog',
      description: 'News, insights, and technical deep dives'
    }
  ];

  return (
    <motion.header
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        scrolled ? 'py-3 bg-dark-lighter/90 backdrop-blur-md' : 'py-6 bg-transparent'
      }`}
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          <Link to="/" className="flex items-center space-x-3">
            <div className="relative w-9 h-9 flex items-center justify-center">
              <img
                src="/icon-mono.png"
                alt="Catena Logo"
                className="w-9 h-9 object-contain"
              />
            </div>
            <span className="font-display font-bold text-xl tracking-tight text-white">Catena</span>
          </Link>

          {/* Desktop Menu */}
          <nav className="hidden md:flex space-x-10">
            <Link
              to="/"
              className="text-gray-300 hover:text-primary transition-colors duration-300 font-medium"
            >
              Home
            </Link>
            <div className="relative">
              <button
                className="text-gray-300 hover:text-primary transition-colors duration-300 font-medium inline-flex items-center"
                onMouseEnter={() => setSolutionsOpen(true)}
                onMouseLeave={() => setSolutionsOpen(false)}
              >
                Solutions
                <svg className="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              <div onMouseEnter={() => setSolutionsOpen(true)} onMouseLeave={() => setSolutionsOpen(false)}>
                <Dropdown items={solutions} show={solutionsOpen} />
              </div>
            </div>
            <div className="relative">
              <button
                className="text-gray-300 hover:text-primary transition-colors duration-300 font-medium inline-flex items-center"
                onMouseEnter={() => setCompanyOpen(true)}
                onMouseLeave={() => setCompanyOpen(false)}
              >
                Company
                <svg className="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              <div onMouseEnter={() => setCompanyOpen(true)} onMouseLeave={() => setCompanyOpen(false)}>
                <Dropdown items={company} show={companyOpen} />
              </div>
            </div>
          </nav>

          <div className="hidden md:flex items-center space-x-4">
            <Link
              to="/login"
              className="btn-outline"
            >
              Log in
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="text-gray-300 hover:text-white focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {mobileMenuOpen ? (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                ) : (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                )}
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {mobileMenuOpen && (
        <motion.div
          className="md:hidden bg-dark-lighter border-t border-gray-800 mt-3"
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link
              to="/"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-dark-lightest"
            >
              Home
            </Link>
            <div className="space-y-1">
              <div className="px-3 py-2 text-base font-medium text-gray-400">Solutions</div>
              {solutions.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-dark-lightest pl-6"
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="space-y-1">
              <div className="px-3 py-2 text-base font-medium text-gray-400">Company</div>
              {company.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-dark-lightest pl-6"
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-700">
              <Link
                to="/login"
                className="block px-3 py-2 rounded-md text-base font-medium bg-dark-lighter border border-gray-700 text-white hover:bg-dark-lightest text-center"
              >
                Log in
              </Link>
            </div>
          </div>
        </motion.div>
      )}
    </motion.header>
  );
};

export default Navbar;
