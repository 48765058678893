/* eslint-disable */

import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import emailjs from '@emailjs/browser';

// Animation variants
const cardVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: (item: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 80,
      damping: 12,
      delay: item * 0.2
    }
  })
};

const CTA = () => {
  const [submitStatus, setSubmitStatus] = useState<'submitting' | 'success' | 'error' | null>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitStatus('submitting');

    try {
      await emailjs.sendForm(
        import.meta.env.VITE_EMAILJS_SERVICE_ID!,
        import.meta.env.VITE_EMAILJS_TEMPLATE_ID!,
        e.target as HTMLFormElement,
        import.meta.env.VITE_EMAILJS_PUBLIC_KEY!
      );

      setSubmitStatus('success');
      (e.target as HTMLFormElement).reset();
      setTimeout(() => setSubmitStatus(null), 5000);
    } catch (error) {
      console.error('Failed to send email:', error);
      setSubmitStatus('error');
      setTimeout(() => setSubmitStatus(null), 5000);
    }
  };

  return (
    <section id="contact" className="section relative overflow-hidden py-24">
      <div className="absolute inset-0 bg-gradient-to-b from-dark-lighter to-dark" />

      <div
        className="absolute -left-40 top-0 w-[600px] h-[600px] rounded-full blob-glow bg-primary/10"
        style={{ zIndex: 1 }}
      />

      <motion.div
        className="container mx-auto relative z-10"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.6 }}
      >
        <motion.div
          className="max-w-6xl mx-auto relative"
          initial={{ opacity: 0, y: 60 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{
            type: "spring",
            stiffness: 50,
            damping: 20,
            delay: 0.1
          }}
        >
          <div className="relative rounded-xl border border-gray-800/30 shadow-lg overflow-hidden">
            {/* Simple, elegant background fade */}
            <div className="absolute inset-0">
              <div className="absolute inset-0 bg-gradient-to-br from-dark-lighter/50 to-dark" />
              <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,_rgba(42,125,156,0.1),_transparent_50%)]" />
            </div>

            {/* Content */}
            <div className="relative p-8 md:p-12 lg:p-16">
              <motion.div
                className="text-center mb-12"
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.7, delay: 0.2 }}
              >
                <div className="inline-block px-4 py-1 bg-dark-lighter rounded-full border border-gray-700 text-primary font-medium mb-4">
                  Get Started Today
                </div>
                <h2 className="text-3xl md:text-5xl font-display font-bold mb-6">
                  Let Catena Handle Your Data Challenges
                </h2>
                <p className="text-lg md:text-xl text-gray-300 max-w-3xl mx-auto">
                  Focus on growing your business while we transform your fragmented supply chain data into actionable intelligence.
                </p>
              </motion.div>

              <div className="max-w-2xl mx-auto">
                <form className="space-y-6" onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <input
                      type="text"
                      name="full_name"
                      placeholder="Full Name"
                      className="w-full p-4 rounded-lg bg-dark-lighter border border-gray-700 text-white placeholder-gray-400 focus:border-primary focus:ring-1 focus:ring-primary transition duration-200"
                      required
                    />
                    <input
                      type="email"
                      name="work_email"
                      placeholder="Work Email"
                      className="w-full p-4 rounded-lg bg-dark-lighter border border-gray-700 text-white placeholder-gray-400 focus:border-primary focus:ring-1 focus:ring-primary transition duration-200"
                      required
                    />
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <input
                      type="tel"
                      name="phone_number"
                      placeholder="Phone Number"
                      className="w-full p-4 rounded-lg bg-dark-lighter border border-gray-700 text-white placeholder-gray-400 focus:border-primary focus:ring-1 focus:ring-primary transition duration-200"
                      required
                    />
                    <input
                      type="text"
                      name="company_name"
                      placeholder="Company Name"
                      className="w-full p-4 rounded-lg bg-dark-lighter border border-gray-700 text-white placeholder-gray-400 focus:border-primary focus:ring-1 focus:ring-primary transition duration-200"
                      required
                    />
                  </div>

                  <textarea
                    name="content"
                    placeholder="Tell us about your supply chain data challenges"
                    rows={4}
                    className="w-full p-4 rounded-lg bg-dark-lighter border border-gray-700 text-white placeholder-gray-400 focus:border-primary focus:ring-1 focus:ring-primary transition duration-200"
                    required
                  ></textarea>

                  <div className="flex items-center space-x-4">
                    <motion.button
                      type="submit"
                      className="btn-primary text-lg px-8 py-4 shadow-glow"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      disabled={submitStatus === 'submitting'}
                    >
                      {submitStatus === 'submitting' ? 'Submitting...' : 'Request Your Demo'}
                    </motion.button>

                    <AnimatePresence>
                      {(submitStatus === 'success' || submitStatus === 'error') && (
                        <motion.div
                          key={submitStatus}
                          initial={{ opacity: 0, x: -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{ opacity: 0, x: -20 }}
                          transition={{ duration: 0.5 }}
                          className={`flex-grow p-4 rounded-lg text-white ${
                            submitStatus === 'success' ? 'bg-green-500/20' : 'bg-red-500/20'
                          }`}
                        >
                          {submitStatus === 'success' ? (
                            <div className="flex items-center">
                              <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                              </svg>
                              <span>Thank you! We&apos;ll be in touch soon.</span>
                            </div>
                          ) : (
                            <div className="flex items-center">
                              <svg className="w-5 h-5 mr-2 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                              </svg>
                              <span>Failed to send. Please try again.</span>
                            </div>
                          )}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default CTA;
